import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemButton, Paper, Grid, Divider, Container } from '@mui/material';
import UserDetails from './Settings/UserDetails';
import ChangePassword from './Settings/ChangePassword';
import Logout from './Settings/Logout';

export default function Settings() {
  const [selectedOption, setSelectedOption] = useState('details');

  const renderContent = () => {
    switch (selectedOption) {
      case 'details':
        return <UserDetails />;
      case 'changePassword':
        return <ChangePassword />;
      case 'logout':
        return <Logout />;
      default:
        return <UserDetails />;
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>  {/* Adding margin to the top */}
      <Grid container>
        {/* Sidebar */}
        <Grid item xs={3}>
          <Paper style={{ height: '100vh', padding: 16 }}>
            <Typography variant="h6" gutterBottom>
              Settings
            </Typography>
            <Divider />
            <List component="nav">
              <ListItem disablePadding>
                <ListItemButton onClick={() => setSelectedOption('details')}>
                  <ListItemText primary="User Details" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => setSelectedOption('changePassword')}>
                  <ListItemText primary="Change Password" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => setSelectedOption('logout')}>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Content Area */}
        <Grid item xs={9}>
          <Box style={{ padding: 16 }}>{renderContent()}</Box>
        </Grid>
      </Grid>
    </Container>
  );
}
