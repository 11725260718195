
const awsmobile = {
    aws_project_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:e446998f-bb31-444a-a9db-5ef51f46c229',
    aws_cognito_region: 'ap-southeast-2',
    aws_user_pools_id: 'ap-southeast-2_1rXrrOwBz',
    aws_user_pools_web_client_id: '61gl9mj0leb8o8ribf27bp8950',
    oauth: {},
    federationTarget: 'COGNITO',
    Storage: {
        AWSS3: {
            bucket: 'prod-user-data-bucket-ynemhbhf',
            region: 'ap-southeast-2',
            isObjectLockEnabled: true
        }
    }
    ,
    aws_cloud_logic_custom: [
        {
            name: '3xg6889dt4',
            endpoint: 'https://3xg6889dt4.execute-api.ap-southeast-2.amazonaws.com/default',
            region: 'ap-southeast-2'
        }
    ]
};

export default awsmobile;
