import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Auth } from 'aws-amplify'; // Import Amplify's Auth module

const CustomToolbar = () => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        // Fetch the current authenticated user's info
        const fetchUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUsername(user.username); // Set the username
            } catch (error) {
                console.error('Error fetching user: ', error);
            }
        };

        fetchUser();
    }, []);

    return (
        <AppBar position="static" style={{ backgroundColor: '#31939f' }}>
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    InsureFlow {username && ` - Welcome, ${username}`}
                </Typography>
                <Box>
                    <Button color="inherit" component={Link} to="/">
                        Home
                    </Button>
                    <Button color="inherit" component={Link} to="/🤯">
                        SOA Maker
                    </Button>
                    <Button color="inherit" component={Link} to="/📚">
                        Policy Library
                    </Button>
                    <Button color="inherit" component={Link} to="/🛠">
                        Settings
                    </Button>
                    {/* Add other links as needed */}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default CustomToolbar;
