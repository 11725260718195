import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Paper,
  Grid,
  Divider,
} from '@mui/material';

export default function ForgotPassword({ onBackToLogin }) {
  const [identifier, setIdentifier] = useState(''); // Can be username or email
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Added confirm password state
  const [step, setStep] = useState(1); // Step 1: Send Code, Step 2: Enter Code and New Password
  const [error, setError] = useState('');

  const handleSendCode = async () => {
    try {
      await Auth.forgotPassword(identifier); // Handle both email or username as identifier
      setStep(2); // Move to the next step
    } catch (error) {
      console.error('Error requesting password reset', error);
      setError(error.message || error);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(identifier, code, newPassword); // Use identifier for username or email
      onBackToLogin(); // Go back to login after reset
    } catch (error) {
      console.error('Error resetting password', error);
      setError(error.message || error);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item>
        <Paper elevation={3} style={{ padding: 16 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography component="h1" variant="h5" style={{ marginBottom: 16 }}>
              Forgot Password
            </Typography>
            {step === 1 ? (
              <form style={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="identifier"
                  label="Username"
                  name="identifier"
                  autoComplete="username"
                  autoFocus
                  value={identifier}
                  onChange={(e) => setIdentifier(e.target.value)}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} onClick={handleSendCode}>
                  Send Reset Code
                </Button>
              </form>
            ) : (
              <form style={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Verification Code"
                  name="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                
                <Divider style={{ margin: '16px 0' }} />  {/* Added Divider to separate code from password fields */}
                
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }} onClick={handleResetPassword}>
                  Reset Password
                </Button>
              </form>
            )}
            <Button fullWidth variant="text" color="primary" onClick={onBackToLogin} style={{ marginTop: 16 }}>
              Back to Login
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
