import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import PublishButton from './PublishButton';

function DynamicHeader({ title, onBack, showBackButton, libraryData, setEditFlag, editFlag }) {
    return (
        <Container maxWidth="lg">
            <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center" 
                mt={5}

            >
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>
                <Box display="flex" alignItems="center">
                    <PublishButton libraryData={libraryData} setEditFlag={setEditFlag} editFlag={editFlag} />
                    <Box padding={1} />
                    {showBackButton && (
                        <Button variant="contained" color="secondary" onClick={onBack}>
                            Back
                        </Button>
                    )}
                </Box>
            </Box>
        </Container>
    );
}

export default DynamicHeader;
