import React from 'react';
import { Box, Typography, TextField, IconButton, Button } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

// Helper function to convert plural titles to singular
const convertTitleToSingular = (title) => {
    const mapping = {
        Coverages: 'Coverage',
        Excesses: 'Excess',
        Premiums: 'Premium',
    };
    return mapping[title] || title.slice(0, -1); // Default case: just remove the last character
};

function PolicyDetailsSection({ companyData, title, items, setItems, updatePolicyDetails, companyName, currentPolicyName, type }) {
    // Ensure items is always an array
    const safeItems = Array.isArray(items) ? items : [];

    // Helper function to generate a unique key
    const generateUniqueKey = (baseKey, index) => {
        let uniqueKey = baseKey;
        let suffix = 2;
        const existingKeys = safeItems.map(([key], i) => (i !== index ? key : null));
        while (existingKeys.includes(uniqueKey)) {
            uniqueKey = `${baseKey}_${suffix}`;
            suffix += 1;
        }
        return uniqueKey;
    };

    // Helper function to validate and potentially modify key
    const validateAndModifyKey = (key, index) => {
        // If the key starts with a number, prepend an underscore
        if (/^\d/.test(key)) {
            key = `_${key}`;
        }

        // Generate a unique key if necessary
        return generateUniqueKey(key, index);
    };

    const handleAddItem = () => {
        setItems([...safeItems, ['', '']]);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = safeItems.filter((_, i) => i !== index);
        setItems(updatedItems);
        updatePolicyDetails(
            companyName,
            currentPolicyName,
            {
                ...companyData.policies[currentPolicyName],
                [type]: Object.fromEntries(updatedItems)
            },
            currentPolicyName
        );
    };

    const handleItemChange = (index, key, value) => {
        const updatedItems = [...safeItems];
        // Validate and modify the key if necessary
        key = validateAndModifyKey(key, index);
        updatedItems[index] = [key, value];
        setItems(updatedItems);
        updatePolicyDetails(
            companyName,
            currentPolicyName,
            {
                ...companyData.policies[currentPolicyName],
                [type]: Object.fromEntries(updatedItems)
            },
            currentPolicyName
        );
    };

    return (
        <Box>
            <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold', mb: 2 }}>
                {title}
            </Typography>
            {safeItems.map(([key, value], index) => (
                <Box display="flex" alignItems="center" mb={1.5} key={index}>
                    <TextField
                        label={convertTitleToSingular(title)} // Use the singular form of the title
                        value={key}
                        onChange={(e) => handleItemChange(index, e.target.value, value)}
                        size="small"
                        sx={{ flex: 3, mr: 1.5 }}
                        InputProps={{
                            style: { fontSize: '0.875rem' },
                        }}
                    />
                    <TextField
                        label="Value"
                        value={value}
                        onChange={(e) => handleItemChange(index, key, e.target.value)}
                        size="small"
                        sx={{ flex: 2, mr: 1.5 }}
                        InputProps={{
                            style: { fontSize: '0.875rem' },
                        }}
                    />
                    <IconButton size="small" onClick={() => handleRemoveItem(index)}>
                        <Remove fontSize="small" />
                    </IconButton>
                </Box>
            ))}
            <Box display="flex" alignItems="center" mt={2}>
                <Box 
                    onClick={handleAddItem} 
                    sx={{ 
                        flex: 1, 
                        bgcolor: 'lightgrey', 
                        p: 1, 
                        textAlign: 'center', 
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '0.875rem',
                        '&:hover': {
                            bgcolor: 'grey',
                            color: 'white',
                        }
                    }}
                >
                    New Row
                </Box>
                <IconButton onClick={handleAddItem} size="small" sx={{ ml: 1 }}>
                    <Add fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
}

export default PolicyDetailsSection;
