// LandingPage.jsx

import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Button } from '@mui/material';

const LandingPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 8 }}>
      <Grid container spacing={4}>
        {/* Left Side: Development Roadmap */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}  sx={{ bgcolor: 'grey.100', height: '100%' }}>
            <CardContent>
                    {/* Main Heading */}
      <Typography variant="h6">
        InsureFlow's Next Phase: Rapid Renewal Methods
      </Typography>

      {/* Introduction */}
      <Typography variant="body2">
        Currently, InsureFlow generates Statements of Advice (SOAs) using spreadsheets or manual input. The upcoming development will introduce rapid renewal methods to enhance efficiency and client service:
      </Typography>

      {/* Feature List */}
      <Typography variant="subtitle2">
        1. Attaching SOAs to Client Profiles
      </Typography>
      <Typography variant="caption" display="block">
        SOAs will be directly linked to client profiles, centralizing documents for easy access and better organization.
      </Typography>

      <Typography variant="subtitle2">
        2. Confirming Policies of Clients
      </Typography>
      <Typography variant="caption" display="block">
        The system will enable advisors to confirm policy details of clients, ensuring accuracy within the system.
      </Typography>

      <Typography variant="subtitle2">
        3. Tracking Policy Timelines
      </Typography>
      <Typography variant="caption" display="block">
        InsureFlow will monitor policies from inception to pre-renewal, providing timelines and automated reminders for important dates.
      </Typography>

      <Typography variant="subtitle2">
        4. Pre-Renewal Process and Documentation
      </Typography>
      <Typography variant="caption" display="block">
        Advisors can create customized pre-renewal plans and generate necessary documents ahead of renewal dates, streamlining the process.
      </Typography>

      {/* Benefits Section */}
      <Typography variant="subtitle1">
        Benefits
      </Typography>

      <Typography variant="caption" display="block">
        <strong>Efficiency Gains:</strong> Saves time for advisors and clients by streamlining processes.
      </Typography>
      <Typography variant="caption" display="block">
        <strong>Enhanced Client Experience:</strong> Improves satisfaction through a smoother renewal process.
      </Typography>
      <Typography variant="caption" display="block">
        <strong>Regulatory Compliance:</strong> Ensures all actions meet industry standards through automated tracking.
      </Typography>

      {/* Conclusion */}
      <Typography variant="body2">
        In summary, these enhancements aim to make InsureFlow more efficient and client-centric by improving SOA management and policy renewal processes.
      </Typography>

            </CardContent>
          </Card>
        </Grid>

        {/* Right Side: Two Vertical Boxes */}
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={4}>
            {/* Box 1: Policy Library */}
            <Grid item>
              <Card elevation={3}  >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" gutterBottom>
                    Policy Library
                  </Typography>
                  <Typography variant="body1">
                    The Policy Library is the central repository for policies and their details. Each policy must be created here before it can be used by the SOA generator. While adding policies, remember to regularly Publish to save your work in the cloud. 
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    href="📚"
                    sx={{ mt: 2 }}
                  >
                    Go to Policy Library
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            {/* Box 2: SOA Generator */}
            <Grid item>
              <Card elevation={3} >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" gutterBottom>
                    SOA Generator
                  </Typography>
                  <Typography variant="body1">
                    The SOA generator can import a correctly formatted Amicus Excel spreadsheet to create a draft policy. After importing, you can review and adjust the policies to generate an SOA Word document. You can also manually add policies as needed, using those created in the Policy Library.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    href="🤯"
                    sx={{ mt: 2 }}
                  >
                    Go to SOA Generator
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPage;
