import React, { useState } from 'react';
import LoginManager from './Login/LoginManager';
import ForgotPassword from './Login/ForgotPassword';
import { CssBaseline, Container } from '@mui/material';

export default function RootLogin() {
  const [view, setView] = useState('login'); // 'login' or 'forgotPassword'

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {view === 'login' ? (
        <LoginManager onForgotPassword={() => setView('forgotPassword')} />
      ) : (
        <ForgotPassword onBackToLogin={() => setView('login')} />
      )}
    </Container>
  );
}
