import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Auth, API, Storage } from 'aws-amplify';  // Import Amplify modules
import awsmobile from '../../../aws-exports';

const GenerateWordDocument = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGenerate = async () => {
    setLoading(true);
    setError(null);

    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      // Get authenticated user details
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;

      console.log(user)

      // Call the Lambda function through API Gateway
      const response = await API.post(awsmobile.aws_cloud_logic_custom[0].name, '/word-doc', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: {
          companies: data.companies,
          fee: data.fee,
          expiry: data.expiry,
          comparison_tables: data.comparison_tables,
          user: {
            username: user.username,
            sub: attributes['sub'],
            email: attributes["email"],
            company: attributes["custom:custom_company_id"]
          }
        }
      });

      // Get the URL of the generated document from S3
      const url = await Storage.get(response.key, { level: 'public' });

      if (url) {
        // Open the document in a new tab to trigger the download
        window.open(url, '_blank');
      } else {
        throw new Error('Failed to retrieve the document URL.');
      }

    } catch (error) {
      setError('Failed to generate the document. Please try again.');
      console.error('Error generating document:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleGenerate} disabled={loading}>
        {loading ? 'Generating...' : 'Generate Document'}
      </Button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </>
  );
};

export default GenerateWordDocument;
