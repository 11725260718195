import React, { useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { Auth, API } from 'aws-amplify';
import awsmobile from '../../../../../aws-exports';

// Define the jump and twist keyframes
const jumpAndTwistAnimation = `
    @keyframes jumpAndTwist {
        0% { transform: translateY(0) rotate(0deg); }
        30% { transform: translateY(-10px) rotate(10deg); }
        50% { transform: translateY(0) rotate(0deg); }
        70% { transform: translateY(-5px) rotate(-10deg); }
        100% { transform: translateY(0) rotate(0deg); }
    }
`;

async function getUserId() {
    const user = await Auth.currentAuthenticatedUser();
    const session2 = await Auth.currentCredentials();
    return session2.identityId;
}

function PublishButton({ libraryData, setEditFlag, editFlag }) {
    const [loading, setLoading] = useState(false);
    const [publishStatus, setPublishStatus] = useState(null);
    const [disableDuringSuccess, setDisableDuringSuccess] = useState(false); // To handle disabling during success animation

    const resetButtonState = () => {
        setTimeout(() => {
            setPublishStatus(null);  // Reset the button status to its default state
            setDisableDuringSuccess(false); // Re-enable the button after success animation
        }, 1000);  // Delay for 2 seconds before resetting
    };

    const handlePublish = async () => {
        if (!editFlag || loading || disableDuringSuccess) return; // Prevent publish action if "disabled"

        setLoading(true);
        setPublishStatus(null);  // Clear any previous status
        setDisableDuringSuccess(false); // Reset in case it's still true from previous action

        try {
            const session = await Auth.currentSession();
            const user = await Auth.currentAuthenticatedUser();
            const token = session.getIdToken().getJwtToken();

            const userId = await getUserId();

            // Make the POST request to the API
            const response = await API.post(awsmobile.aws_cloud_logic_custom[0].name, '/update-lib', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    new_data: libraryData,
                    userId: userId,
                    userName: user.username,
                    email: user.attributes.email
                }
            });

            console.log(response)

            setPublishStatus('success');  // Set status to success
            setEditFlag(false);  // Reset edit flag after successful publish
            setDisableDuringSuccess(true); // Disable button during success animation
            resetButtonState();  // Animate button back to default after 2 seconds
        } catch (error) {
            console.error('Error:', error);
            setPublishStatus('error');  // Set status to error
            resetButtonState();  // Animate button back to default after 2 seconds
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <style>{jumpAndTwistAnimation}</style> {/* Add the jump and twist animation */}
            <Button
                variant="contained"
                onClick={handlePublish}
                sx={{
                    backgroundColor:
                        publishStatus === 'success'
                            ? 'green'
                            : publishStatus === 'error'
                            ? 'red'
                            : !editFlag || loading || disableDuringSuccess
                            ? 'grey'
                            : 'primary.main',
                    color: !editFlag || loading || disableDuringSuccess ? 'darkgray' : 'white',
                    transition: 'background-color 0.5s ease, transform 0.5s ease',  // Smooth transition for jump and twist
                    cursor:
                        !editFlag || loading || disableDuringSuccess ? 'not-allowed' : 'pointer',
                    pointerEvents:
                        !editFlag || loading || disableDuringSuccess ? 'none' : 'auto', // Block click events when "disabled"
                    '&:hover': {
                        backgroundColor:
                            publishStatus === 'success'
                                ? 'green'
                                : publishStatus === 'error'
                                ? 'red'
                                : !editFlag || loading || disableDuringSuccess
                                ? 'grey'
                                : 'primary.dark',
                    },
                    animation: publishStatus === 'success' ? 'jumpAndTwist 0.5s' : 'none', // Apply jump and twist animation on success
                }}
            >
                {publishStatus === 'success'
                    ? 'Success'
                    : publishStatus === 'error'
                    ? 'Error'
                    : 'Publish'}
                {loading && <CircularProgress size={20} style={{ marginLeft: '10px' }} />}
            </Button>
        </Box>
    );
}

export default PublishButton;
