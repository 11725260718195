import React from 'react';
import { useLocation } from 'react-router-dom';
import Toolbar from './Toolbar'; // Assume Toolbar is another component you've created

const Layout = ({ children }) => {
    const location = useLocation();

    const shouldShowToolbar = location.pathname !== '/login';

    return (
        <div>
            {shouldShowToolbar && <Toolbar />}
            <main>{children}</main>
        </div>
    );
};

export default Layout;
