export default function updateComparisonData(xlxsData, dataLibrary) {
  //TODO: Currently companies anre not handled corrently here, if they are not in the data library they will not be added, fixing this will also require the comparison table to be correctly found elsewhere 
    const outputComparisonData = { companies: {}, comparison_tables: {}, fee: 0, expiry: 1 };
  
    // Iterate through each company in the xlxsData
    Object.keys(xlxsData).forEach((company) => {
      const xlxsCompanyData = xlxsData[company];
      const companyPolicies = xlxsCompanyData['policies']

      if (xlxsCompanyData.fee_sum > outputComparisonData.fee){
        outputComparisonData.fee = xlxsCompanyData.fee_sum
      }

      // Find the corresponding company in the data library
      if (dataLibrary.companies[company]) {
        const companyData = dataLibrary.companies[company];
  
        // Initialize company if it doesn't exist in comparisonData
        if (!outputComparisonData.companies[company]) {
            outputComparisonData.companies[company] = {
            legal_name: companyData.legal_name,
            insurer_strength_rating: companyData.insurer_strength_rating,
            policies: {}
          };
        }
  
        // Iterate through each policy under the company
        Object.keys(companyPolicies).forEach((policy) => {
          const policyDetails = companyPolicies[policy];

          const { coverages_and_indemnities, excesses, exclusions, premiums } = policyDetails;

          const coverage = {};

          // Check if coverages_and_indemnities exists
          if (coverages_and_indemnities && Array.isArray(coverages_and_indemnities)) {
            coverages_and_indemnities.forEach(item => {
              const { coverage: coverageName, indemnity } = item;
        
              // Add coverage: indemnity to the coverage object
              if (coverageName && indemnity) {
                coverage[coverageName] = indemnity;
              }
            });
          }

          const procExclusions = {};
          const unfoundExclusions = [];

          // Check if the company exists in the library
          if (dataLibrary.companies[company]) {
              const companyData = dataLibrary.companies[company];

              // Check if the policy exists in the company data
              if (companyData.policies[policy]) {
              const policyData = companyData.policies[policy];

              // Iterate through exclusions in policyDetails
              if (exclusions && Array.isArray(exclusions)) {
                  exclusions.forEach((exclusionName) => {
                  // Check if the exclusion exists in the policy data of the library
                  if (policyData.exclusions && policyData.exclusions[exclusionName]) {
                      procExclusions[exclusionName] = policyData.exclusions[exclusionName];
                  } else {
                      // Add to unfound exclusions if not found in the policy
                      unfoundExclusions.push(exclusionName);
                  }
                  });
              }
              } else {
              // If the policy is not found, add all exclusions to the unfoundExclusions list
              unfoundExclusions.push(...(policyDetails.exclusions || []));
              }
          } else {
              // If the company is not found, add all exclusions to the unfoundExclusions list
              unfoundExclusions.push(...(policyDetails.exclusions || []));
          }

          outputComparisonData.companies[company].policies[policy] = {
            coverage : coverage,
            excesses : excesses,
            exclusions : procExclusions,
            unfoundExclusions: unfoundExclusions,
            premiums: premiums
          }

  
        });
      }
    });
  
    return outputComparisonData;
  }
  