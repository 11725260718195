import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { TextField, Button, Alert, Box, CircularProgress } from '@mui/material';

export default function UserDetails() {
  const [userAttributes, setUserAttributes] = useState({
    email: '',
    username: '',
    custom_company_id: '',
  });
  const [loading, setLoading] = useState(true);  // Loading state to show progress
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserAttributes({
          email: user.attributes.email || '',
          username: user.username || '',
          custom_company_id: user.attributes['custom:custom_company_id'] || '',  // Custom company ID
        });
        setLoading(false);  // Stop loading when attributes are fetched
      } catch (error) {
        console.error('Error fetching user attributes:', error);
        setError('Failed to load user details.');
        setLoading(false);
      }
    };

    fetchUserAttributes();
  }, []);

  const handleUpdateAttributes = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        email: userAttributes.email,
      });
      setSuccess('User details updated successfully.');
    } catch (error) {
      console.error('Error updating user details:', error);
      setError('Failed to update user details.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {/* Username (read-only) */}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Username"
        value={userAttributes.username}
        InputProps={{
          readOnly: true,  // Make it read-only
        }}
        disabled
      />
      
      {/* Custom Company ID (read-only) */}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Company ID"
        value={userAttributes.custom_company_id}
        InputProps={{
          readOnly: true,  // Make it read-only
        }}
        disabled
      />

      {/* Email (editable) */}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Email"
        value={userAttributes.email}
        onChange={(e) => setUserAttributes({ ...userAttributes, email: e.target.value })}
      />

      <Button
        onClick={handleUpdateAttributes}
        variant="contained"
        color="primary"
        style={{ marginTop: 16 }}
      >
        Update User Details
      </Button>

      {error && <Alert severity="error" style={{ marginTop: 16 }}>{error}</Alert>}
      {success && <Alert severity="success" style={{ marginTop: 16 }}>{success}</Alert>}
    </Box>
  );
}
