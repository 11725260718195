import React, { useState } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
    Box, Container, TextField, Pagination, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import HistoricRollback from "./PolicyView/Formatting/HistoricRollback"

function TotalView({ 
    libraryData, 
    setLibraryData, 
    filteredCompanies, 
    filter, 
    currentPage, 
    itemsPerPage, 
    handlePageChange, 
    handleFilterChange, 
    handleRowClick, 
    saveCompany, 
    newCompanyName, 
    setNewCompanyName, 
    setEditFlag }) {

    const [open, setOpen] = useState(false);

    // Pagination logic
    const paginatedCompanies = filteredCompanies.slice(
        (currentPage - 1) * itemsPerPage, 
        currentPage * itemsPerPage
    );

    // Handle saving company when the 'Enter' key is pressed in the input field
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default form submission or unexpected behavior
            handleSaveCompany();
        }
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const handleAddCompany = (event) => {
        event.currentTarget.blur(); // Blur the "Add Company" button after clicking it
        setOpen(true);
    };

    const handleSaveCompany = () => {
        saveCompany();
        closeDialog(); // Ensure the dialog closes after saving
    };

    return (
        <Container maxWidth="md">
            <Box mt={5} mb={2}>
                <TextField
                    label="Filter Companies"
                    variant="outlined"
                    fullWidth
                    value={filter}
                    onChange={handleFilterChange}
                    margin="normal"
                    autoComplete="off"  // Disable autocomplete
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <HistoricRollback 
                        setLibraryData={setLibraryData} 
                        setEditFlag={setEditFlag}
                    />
                    <Box margin={1}/>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleAddCompany}
                    >
                        Add Company
                    </Button>
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                Company Name
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                Number of Policies
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedCompanies.map((company, index) => (
                            <TableRow
                                key={index}
                                onClick={() => handleRowClick(company)}
                                style={{
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ADD8E6'}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
                            >
                                <TableCell align="center">{company}</TableCell>
                                <TableCell align="center">
                                    {libraryData.companies[company] ? Object.keys(libraryData.companies[company].policies).length : 0}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={2} display="flex" justifyContent="center">
                <Pagination 
                    count={Math.ceil(filteredCompanies.length / itemsPerPage)} 
                    page={currentPage} 
                    onChange={handlePageChange} 
                    color="primary"
                />
            </Box>
            {/* Dialog for adding a new company */}
            <Dialog open={open} onClose={closeDialog}>
                <DialogTitle>Add New Company</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the name of the new company you want to add.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Company Name"
                        type="text"
                        fullWidth
                        value={newCompanyName}
                        onChange={(e) => setNewCompanyName(e.target.value)}
                        onKeyDown={handleKeyPress}  // Save on Enter key press only for the input field
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveCompany} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default TotalView;
