import React, { useState, useEffect } from 'react';
import {
    Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Button, IconButton, Pagination, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { Edit, Add, Remove } from '@mui/icons-material';

function PolicyExclusionsSection({ companyData, exclusions, setExclusions, updatePolicyDetails, companyName, currentPolicyName }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredExclusions, setFilteredExclusions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(5);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editExclusion, setEditExclusion] = useState({ name: '', text: '', originalName: '' });
    const [exclusionNameError, setExclusionNameError] = useState('');
    
    const safeExclusions = Array.isArray(exclusions) ? exclusions : Object.entries(exclusions || {});

    useEffect(() => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        const filtered = safeExclusions.filter(([name]) => name.toLowerCase().includes(lowercasedSearchTerm));
        setFilteredExclusions(filtered);
    }, [searchTerm, safeExclusions]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleOpenModal = (name = '', text = '') => {
        setEditExclusion({ name, text, originalName: name });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setExclusionNameError('');
    };

    const handleSaveExclusion = () => {
        if (!editExclusion.name.trim()) {
            setExclusionNameError('Exclusion name cannot be empty.');
            return;
        }

        const updatedExclusions = [...safeExclusions];
        const existingIndex = updatedExclusions.findIndex(([name]) => name === editExclusion.originalName);

        if (existingIndex !== -1) {
            updatedExclusions[existingIndex] = [editExclusion.name, editExclusion.text];
        } else {
            updatedExclusions.push([editExclusion.name, editExclusion.text]);
        }

        setExclusions(updatedExclusions);
        updatePolicyDetails(
            companyName,
            currentPolicyName,
            { ...companyData.policies[currentPolicyName], exclusions: Object.fromEntries(updatedExclusions) },
            currentPolicyName
        );
        handleCloseModal();
    };

    const handleAddExclusion = () => {
        setEditExclusion({ name: '', text: '', originalName: '' });
        setIsModalOpen(true);
    };

    const handleRemoveItem = (index) => {
        const updatedExclusions = safeExclusions.filter((_, i) => i !== index);
        setExclusions(updatedExclusions);
        updatePolicyDetails(
            companyName,
            currentPolicyName,
            { ...companyData.policies[currentPolicyName], exclusions: Object.fromEntries(updatedExclusions) },
            currentPolicyName
        );
    };

    const displayedExclusions = filteredExclusions.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    return (
        <Box mt={4}>
            <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                Policy Exclusions
            </Typography>

            <TextField
                label="Search Exclusions"
                variant="outlined"
                fullWidth
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
                InputProps={{
                    style: { fontSize: '0.875rem' }
                }}
            />

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Exclusion Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '0.875rem' }} align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedExclusions.map(([name, text], index) => (
                            <TableRow key={index} hover>
                                <TableCell sx={{ padding: '6px 8px', fontSize: '0.875rem' }}>{name}</TableCell>
                                <TableCell sx={{ padding: '6px 8px' }} align="right">
                                    <IconButton size="small" onClick={() => handleOpenModal(name, text)}>
                                        <Edit fontSize="small" />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleRemoveItem(index)}>
                                        <Remove fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Pagination
                    count={Math.ceil(filteredExclusions.length / rowsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="small"
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleAddExclusion}
                    sx={{ fontSize: '0.875rem' }}
                >
                    Add Exclusion
                </Button>
            </Box>

            <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle sx={{ fontSize: '1rem' }}>{editExclusion.originalName ? 'Edit Exclusion' : 'Add Exclusion'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Exclusion Name"
                        value={editExclusion.name}
                        onChange={(e) => setEditExclusion({ ...editExclusion, name: e.target.value })}
                        fullWidth
                        margin="normal"
                        size="small"
                        error={Boolean(exclusionNameError)}
                        helperText={exclusionNameError}
                    />
                    <TextField
                        label="Exclusion Text"
                        value={editExclusion.text}
                        onChange={(e) => setEditExclusion({ ...editExclusion, text: e.target.value })}
                        onKeyDown={(e) => {
                            if (e.key === 'Tab') {
                            e.preventDefault();

                            // Get the current selection positions
                            const { selectionStart, selectionEnd, value } = e.target;

                            // Insert a tab character at the cursor position
                            const newValue =
                                value.substring(0, selectionStart) + '\t' + value.substring(selectionEnd);

                            // Update the TextField's value
                            setEditExclusion({ ...editExclusion, text: newValue });

                            // Move the cursor to the correct position after the tab
                            e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
                            }
                        }}
                        multiline
                        fullWidth
                        rows={8}
                        margin="normal"
                        size="small"
                        inputProps={{
                            style: {
                            fontFamily: 'monospace',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            fontSize: '0.875rem',
                            },
                        }}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveExclusion} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default PolicyExclusionsSection;
