import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Layout from './Layout';

const ProtectedRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setIsAuthenticated(true);
                setLoading(false);
            })
            .catch(() => {
                setIsAuthenticated(false);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? <Layout><Outlet /></Layout> : <Navigate to="/login" />;
};

export default ProtectedRoute;
