import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { 
    Dialog, DialogTitle, DialogContent, DialogActions, Button, 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    CircularProgress, Paper, Pagination 
} from '@mui/material';

const HistoricRollback = ({ setLibraryData, setEditFlag }) => {
    const [open, setOpen] = useState(false);  // Handle modal open state here
    const [loading, setLoading] = useState(false);
    const [historicFiles, setHistoricFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);  // Pagination state
    const itemsPerPage = 10;  // Items per page

    useEffect(() => {
        if (open) {
            fetchHistoricFiles();
        }
    }, [open]);

    const fetchHistoricFiles = async () => {
        setLoading(true);
        try {
            const files = await Storage.list('shared-with-authenticated/insurance-library/history/');
    
            // Filter out any files that are zero bytes in size (potential folder placeholder files)
            const validFiles = files.results.filter(file => file.size > 0 && !file.key.endsWith('/'));
            
            const sortedFiles = validFiles
                .map(file => {
                    const parts = file.key.split('/');
                    const fileName = parts[parts.length - 1];
                    const [username, _, datePart] = fileName.split('_');
                    const formattedDate = datePart.replace('.json', '');
                    return { key: file.key, username, date: formattedDate };
                })
                .sort((a, b) => new Date(b.date.split('-').reverse().join('-')) - new Date(a.date.split('-').reverse().join('-')));
    
            console.log(sortedFiles);
            setHistoricFiles(sortedFiles);
        } catch (error) {
            console.error("Error fetching historic files: ", error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleFileClick = async (key) => {
        try {
            const fileData = await Storage.get(key, { download: true });
            const jsonData = await fileData.Body.text();
            const data = JSON.parse(jsonData);
            setLibraryData(data);
            handleClose();  // Close modal after rollback
            setEditFlag(JSON.parse(jsonData))
        } catch (error) {
            console.error("Error fetching file: ", error);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Pagination logic
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const paginatedFiles = historicFiles.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <>
            <Button variant="contained" color="secondary" onClick={handleOpen} aria-hidden={false}>
                Roll Back
            </Button>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" aria-hidden={false}>
                <DialogTitle>Rollback to a Previous Version</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedFiles.map((file, index) => (
                                        <TableRow 
                                            key={index} 
                                            hover 
                                            onClick={() => handleFileClick(file.key)} 
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <TableCell>{file.username}</TableCell>
                                            <TableCell>{file.date}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Pagination
                        count={Math.ceil(historicFiles.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        style={{ margin: '0 auto' }}
                    />
                    <Button onClick={handleClose} color="secondary" aria-hidden={false}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HistoricRollback;
