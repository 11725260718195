import React from 'react';
import { Box } from '@mui/material';

function DividerLine() {
    return (
        <Box
            sx={{
                width: '100%',       // Full width of the container
                height: '2px',       // Thickness of the line
                bgcolor: 'darkgrey', // Color of the line
                marginY: 2,          // Vertical margin for spacing
            }}
        />
    );
}

export default DividerLine;
