import React, { useState } from 'react';
import { Button, Box, Alert } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  const [error, setError] = useState('');  // useState for error handling
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate('/login');  // Navigate back to the login page after successful logout
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to log out. Please try again.');
    }
  };

  return (
    <Box>
      <Button
        onClick={handleLogout}
        variant="contained"
        color="secondary"
        style={{ marginTop: 16 }}
      >
        Logout
      </Button>

      {/* Display error if logout fails */}
      {error && <Alert severity="error" style={{ marginTop: 16 }}>{error}</Alert>}
    </Box>
  );
}
