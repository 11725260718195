import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { TextField, Button, Alert, Box, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      // Change password using old password and new password
      await Auth.changePassword(user, oldPassword, newPassword);
      setSuccess('Password changed successfully.');
      setError(''); // Clear any previous errors
      
      // Automatically log out the user after successful password change
      await Auth.signOut();
      navigate('/login');  // Redirect to login page
    } catch (error) {
      console.error('Error changing password:', error);
      setError('Failed to change password. Please make sure your old password is correct.');
    }
  };

  return (
    <Box>
      {/* Old Password Field */}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Current Password"
        type="password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />

      {/* 1px Divider Line */}
      <Divider style={{ margin: '10px 0', backgroundColor: '#000', height: '1px' }} />

      {/* New Password Field */}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      {/* Confirm New Password Field */}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Confirm New Password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Button
        onClick={handlePasswordChange}
        variant="contained"
        color="primary"
        style={{ marginTop: 16 }}
      >
        Change Password
      </Button>
      {/* Error Alert */}
      {error && <Alert severity="error" style={{ marginTop: 16 }}>{error}</Alert>}
      {/* Success Alert */}
      {success && <Alert severity="success" style={{ marginTop: 16 }}>{success}</Alert>}
    </Box>
  );
}
