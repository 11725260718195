import React from 'react';
import { Box, Typography, TextField, IconButton } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';

function PolicyWordingSection({ companyData, wordingLinks, setWordingLinks, updatePolicyDetails, companyName, currentPolicyName }) {
    const safeWordingLinks = Array.isArray(wordingLinks) ? wordingLinks : [];

    const generateUniqueTitle = (baseTitle, index) => {
        let uniqueTitle = baseTitle;
        let suffix = 2;
        const existingTitles = safeWordingLinks.map(([title], i) => (i !== index ? title : null));
        while (existingTitles.includes(uniqueTitle)) {
            uniqueTitle = `${baseTitle}_${suffix}`;
            suffix += 1;
        }
        return uniqueTitle;
    };

    const validateAndModifyTitle = (title, index) => {
        if (/^\d/.test(title)) {
            title = `_${title}`;
        }
        return generateUniqueTitle(title, index);
    };

    const handleAddWording = () => {
        if (safeWordingLinks.some(([title, link]) => !title || !link)) {
            return;
        }
        setWordingLinks([...safeWordingLinks, ['', '']]);
    };

    const handleRemoveWording = (index) => {
        const updatedWordings = safeWordingLinks.filter((_, i) => i !== index);
        setWordingLinks(updatedWordings);
        updatePolicyDetails(
            companyName,
            currentPolicyName,
            {
                ...companyData.policies[currentPolicyName],
                wording_links: Object.fromEntries(updatedWordings),
            },
            currentPolicyName
        );
    };

    const handleWordingChange = (index, title, link) => {
        const updatedWordings = [...safeWordingLinks];
        title = validateAndModifyTitle(title, index);
        updatedWordings[index] = [title, link];
        setWordingLinks(updatedWordings);

        const updatedWordingLinks = Object.fromEntries(updatedWordings);

        updatePolicyDetails(
            companyName,
            currentPolicyName,
            {
                ...companyData.policies[currentPolicyName],
                wording_links: updatedWordingLinks,
            },
            currentPolicyName
        );
    };

    return (
        <Box mt={4}>
            <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                Policy Wordings
            </Typography>
            {safeWordingLinks.map(([title, link], index) => (
                <Box display="flex" alignItems="center" mb={1} key={index}>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={(e) => handleWordingChange(index, e.target.value, link)}
                        fullWidth
                        size="small"
                        sx={{ flex: 1, marginRight: 1 }}
                        InputProps={{
                            style: { fontSize: '0.875rem' }, // Reduced text size
                        }}
                    />
                    <TextField
                        label="Link"
                        value={link}
                        onChange={(e) => handleWordingChange(index, title, e.target.value)}
                        fullWidth
                        size="small"
                        sx={{ flex: 2, marginRight: 1 }}
                        InputProps={{
                            style: { fontSize: '0.875rem' }, // Reduced text size
                        }}
                    />
                    <IconButton onClick={() => handleRemoveWording(index)} size="small">
                        <Remove />
                    </IconButton>
                </Box>
            ))}
            <Box display="flex" alignItems="center" mt={1}>
                <Box
                    onClick={handleAddWording}
                    sx={{
                        flex: 1,
                        bgcolor: 'lightgrey',
                        p: 0.75,
                        textAlign: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '0.875rem', // Reduced font size
                        '&:hover': {
                            bgcolor: 'grey',
                            color: 'white',
                        },
                    }}
                >
                    New Wording
                </Box>
                <IconButton onClick={handleAddWording} sx={{ marginLeft: 1 }} size="small">
                    <Add />
                </IconButton>
            </Box>
        </Box>
    );
}

export default PolicyWordingSection;
