import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import TotalView from './libraryManager/TotalView';
import CompanyView from './libraryManager/CompanyView';
import PolicyView from './libraryManager/PolicyView';
import { Box, Container, CircularProgress } from '@mui/material';
import DynamicHeader from './libraryManager/PolicyView/Formatting/DynamicHeader';

function LibraryOrchestrator() {
    const [libraryData, setLibraryData] = useState(null);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('');
    const [newCompanyName, setNewCompanyName] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [editFlag, setEditFlag] = useState(false);  // Edit flag to track unsaved changes
    const [loading, setLoading] = useState(true); // Loading state
    const itemsPerPage = 5;

    // Fetch the library data from S3
    useEffect(() => {
        const fetchLibraryData = async () => {
            try {
                const fileData = await Storage.get('shared-with-authenticated/insurance-library/latest/library:latest.json', {
                    download: true,
                    cacheControl: 'no-cache' // Prevent cache
                });
                const jsonData = await fileData.Body.text();
                const data = JSON.parse(jsonData);
                setLibraryData(data);
                setFilteredCompanies(Object.keys(data.companies || {}).reverse());
            } catch (error) {
                console.error('Error fetching library data, using empty file:', error);
                setLibraryData({ companies: {} });
                setFilteredCompanies([]);
            } finally {
                setLoading(false); // End loading state once fetch is complete
            }
        };

        fetchLibraryData();
    }, []);

    // Add the beforeunload event to handle leaving the page when editFlag is true
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (editFlag) {
                event.preventDefault();
                event.returnValue = '';  // This triggers the confirmation dialog
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [editFlag]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleFilterChange = (event) => {
        const value = event.target.value.toLowerCase();
        setFilter(value);
        const filtered = Object.keys(libraryData.companies || {}).filter(company =>
            company.toLowerCase().includes(value)
        ).reverse();
        setFilteredCompanies(filtered);
        setCurrentPage(1);
    };

    const handleSaveCompany = () => {
        if (newCompanyName && !libraryData.companies[newCompanyName]) {
            const updatedLibraryData = {
                ...libraryData,
                companies: {
                    ...libraryData.companies,
                    [newCompanyName]: {
                        legal_name: "",
                        insurer_strength_rating: "",
                        policies: {}
                    }
                }
            };

            setLibraryData(updatedLibraryData);
            setFilteredCompanies(Object.keys(updatedLibraryData.companies).reverse());
            setEditFlag(true);  // Set edit flag when library data is updated
        }

        setNewCompanyName('');
    };

    const handleRowClick = (companyName) => {
        setSelectedCompany({ ...libraryData.companies[companyName], name: companyName });
    };

    const updateFromHistoric = (newLibraryData) => {
        setFilter('');
        setFilteredCompanies(Object.keys(newLibraryData.companies).reverse());
        setLibraryData(newLibraryData);
        setEditFlag(true); // Indicate that the data has been updated
    };

    const handleGoBack = () => {
        if (selectedPolicy) {
            setSelectedPolicy(null);  // Go back to company view if coming from policy view
        } else {
            setSelectedCompany(null);  // Go back to total view if coming from company view
        }
    };

    const handlePolicyClick = (companyName, policyName) => {
        setSelectedPolicy(policyName);  // Set the selected policy
    };

    const updateCompanyDetails = (originalName, updatedCompanyData) => {
        setLibraryData(prevLibraryData => {
            const updatedLibraryData = { ...prevLibraryData };

            if (originalName !== updatedCompanyData.name) {
                delete updatedLibraryData.companies[originalName];
            }

            updatedLibraryData.companies[updatedCompanyData.name] = {
                legal_name: updatedCompanyData.legal_name,
                insurer_strength_rating: updatedCompanyData.insurer_strength_rating,
                policies: updatedCompanyData.policies
            };

            setEditFlag(true);  // Set edit flag when company data is updated

            return updatedLibraryData;
        });

        setFilteredCompanies(prevFilteredCompanies => {
            const updatedFilteredCompanies = prevFilteredCompanies.filter(company => company !== originalName);
            updatedFilteredCompanies.unshift(updatedCompanyData.name);
            return updatedFilteredCompanies;
        });

        setSelectedCompany(updatedCompanyData);  // Update the selected company
    };

    const updatePolicyDetails = (companyName, originalPolicyName, updatedPolicyData, newPolicyName) => {
        setLibraryData(prevLibraryData => {
            const updatedLibraryData = { ...prevLibraryData };
            const company = updatedLibraryData.companies[companyName];

            if (originalPolicyName !== newPolicyName) {
                delete company.policies[originalPolicyName];
            }

            company.policies[newPolicyName] = updatedPolicyData;

            setEditFlag(true);  // Set edit flag when policy data is updated

            return updatedLibraryData;
        });

        setSelectedCompany(prevCompany => ({
            ...prevCompany,
            policies: {
                ...prevCompany.policies,
                [newPolicyName]: updatedPolicyData
            }
        }));

        setSelectedPolicy(newPolicyName);
    };

    const updateComparisonTables = (comparisonTableKey, updatedTableData) => {
        setLibraryData(prevLibraryData => {
            const updatedLibraryData = { ...prevLibraryData };
            updatedLibraryData.comparison_tables[comparisonTableKey] = updatedTableData;

            setEditFlag(true);  // Set edit flag when comparison table data is updated

            return updatedLibraryData;
        });
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            {selectedPolicy ? (
                <Container maxWidth="lg">
                    <Box mt={5} mb={5}>
                        <DynamicHeader 
                            title={`${selectedCompany.name} - ${selectedPolicy}`} 
                            onBack={handleGoBack} 
                            showBackButton={true}
                            libraryData={libraryData}
                            editFlag={editFlag}
                            setEditFlag={setEditFlag}
                        />
                        <PolicyView
                            libraryData={libraryData}
                            companyData={selectedCompany}
                            comparisonData={libraryData.comparison_tables}
                            policyName={selectedPolicy}
                            companyName={selectedCompany.name}
                            goBack={handleGoBack}
                            updatePolicyDetails={updatePolicyDetails}
                            updateComparisonTables={updateComparisonTables}
                        />
                    </Box>
                </Container>
            ) : selectedCompany ? (
                <Container maxWidth="lg">
                    <Box mt={5} mb={5}>
                        <DynamicHeader 
                            title={selectedCompany.name} 
                            onBack={handleGoBack} 
                            showBackButton={true}
                            libraryData={libraryData}
                            editFlag={editFlag}
                            setEditFlag={setEditFlag}
                        />
                        <CompanyView
                            companyData={selectedCompany}
                            goBack={handleGoBack}
                            updateCompanyDetails={updateCompanyDetails}
                            handlePolicyClick={handlePolicyClick}
                            setLibraryData={setLibraryData}
                            libraryData={libraryData}
                        />
                    </Box>
                </Container>
            ) : (
                <Container maxWidth="md">
                    <Box mt={5} mb={5}>
                        <DynamicHeader 
                            title="Companies List" 
                            onBack={null} 
                            showBackButton={false}
                            libraryData={libraryData}
                            editFlag={editFlag}
                            setEditFlag={setEditFlag}
                        />
                        <TotalView
                            libraryData={libraryData}
                            setLibraryData={updateFromHistoric}
                            setEditFlag={setEditFlag}
                            filteredCompanies={filteredCompanies}
                            filter={filter}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            handlePageChange={handlePageChange}
                            handleFilterChange={handleFilterChange}
                            handleRowClick={handleRowClick}
                            saveCompany={handleSaveCompany}
                            newCompanyName={newCompanyName}
                            setNewCompanyName={setNewCompanyName}
                        />
                    </Box>
                </Container>
            )}
        </Box>
    );
}

export default LibraryOrchestrator;
