import React, { useState, useEffect } from 'react';
import { Divider, Box, Button, Typography, TextField, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Paper, FormControlLabel, Switch } from '@mui/material';
import { Remove } from '@mui/icons-material';
import SelectComponent from 'react-select';

import ExclusionsTable from './PolicySelector/exclusionsSection';

function PolicySelector({
  data, selectedCompany, setSelectedCompany, selectedPolicy, setSelectedPolicy, toggleExclusionSelection,
  selectedExclusions, coverages, setCoverages, excesses, setExcesses,
  premiums, setPremiums, editMode,
  unfoundExclusions, setUnfoundExclusions
}) {
  const [exclusionFilter, setExclusionFilter] = useState('');
  const [potentialCoverages, setPotentialCoverages] = useState([]);
  const [potentialExcesses, setPotentialExcesses] = useState([]);
  const [potentialPremiums, setPotentialPremiums] = useState([]);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [companyExists, setCompanyExists] = useState(true);  // State for checking if the selected company exists
  const [policyExists, setPolicyExists] = useState(true);  // State for checking if the selected policy exists
  
  useEffect(() => {
    if (selectedCompany && data.companies[selectedCompany]) {
      setCompanyExists(true);
      if (selectedPolicy && data.companies[selectedCompany].policies[selectedPolicy]) {
        setPolicyExists(true);
  
        // Populate the data only if the company and policy exist
        const policyData = data.companies[selectedCompany].policies[selectedPolicy];
        setPotentialCoverages(Object.entries(policyData.coverage || {}));
        setPotentialExcesses(Object.entries(policyData.excesses || {}));
        setPotentialPremiums(Object.entries(policyData.premiums || {}));
      } else {
        setPolicyExists(false);
        setPotentialCoverages([]);
        setPotentialExcesses([]);
        setPotentialPremiums([]);
      }
    } else {
      setCompanyExists(false);
      setPolicyExists(false);
      setPotentialCoverages([]);
      setPotentialExcesses([]);
      setPotentialPremiums([]);
    }
  }, [selectedPolicy, selectedCompany, data]);
  
  

  const handleAddCustomOption = (setOption, options) => {
    setOption([...options, ['', '']]);
  };

  const handleRemoveCustomOption = (setOption, index, options) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOption(updatedOptions);
  };

  const handleBlur = (index, setOption, options) => {
    const [key, value] = options[index];
    const newKey = generateUniqueKey(options, key, index);
    if (newKey !== key) {
      const updatedOptions = [...options];
      updatedOptions[index] = [newKey, value || ""];
      setOption(updatedOptions);
    } else if (value === "") {
      const updatedOptions = [...options];
      updatedOptions[index] = [key, ""];
      setOption(updatedOptions);
    }
  };

  const handleChange = (index, setOption, options, key, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = [key, value];
    setOption(updatedOptions);
  };

  const generateUniqueKey = (options, baseKey, excludeIndex = -1) => {
    let uniqueKey = baseKey;
    let suffix = 1;
    const existingKeys = options.map(([key], index) => (index !== excludeIndex ? key : null));
    while (existingKeys.includes(uniqueKey)) {
      uniqueKey = `${baseKey}_${suffix}`;
      suffix += 1;
    }
    return uniqueKey;
  };

  const handleSelectChange = (selectedItem, setOption, options, defaultValues) => {
    if (selectedItem.value === 'custom') {
      handleAddCustomOption(setOption, options);
    } else if (selectedItem) {
      const newKey = generateUniqueKey(options, selectedItem.value);
      setOption([...options, [newKey, defaultValues[selectedItem.value] || ""]]);
    }
  };

  const availableCompanies = Object.keys(data.companies).map(company => ({
    label: company,
    value: company,
  }));

  const availablePolicies = selectedCompany 
    ? Object.keys(data.companies[selectedCompany].policies)
      .map(policy => ({
        label: policy,
        value: policy,
      }))
    : [];

  let filteredExclusions = selectedPolicy && data.companies[selectedCompany]?.policies[selectedPolicy]?.exclusions
    ? Object.keys(data.companies[selectedCompany].policies[selectedPolicy].exclusions)
      .filter(exclusion =>
        exclusion.toLowerCase().includes(exclusionFilter.toLowerCase())
      )
    : [];

  if (showOnlySelected) {
    filteredExclusions = filteredExclusions.filter(exclusion => selectedExclusions.includes(exclusion));
  }

  const handleUnfoundExclusionClick = (exclusion) => {
    setUnfoundExclusions((prevUnfound) => prevUnfound.filter((item) => item !== exclusion)); // Remove from unfound exclusions
    setExclusionFilter(exclusion); // Set exclusion filter to the clicked exclusion text
  };
  

  return (
    <>
      <Typography variant="h6" sx={{ marginTop: 2, fontSize: 16 }}>Select Company</Typography>
      <SelectComponent
        options={availableCompanies}
        onChange={(e) => {
          setSelectedCompany(e.value);
          setSelectedPolicy('');
        }}
        value={selectedCompany ? { label: selectedCompany, value: selectedCompany } : null}
        placeholder="Select Company"
        menuPortalTarget={document.body}
        isDisabled={editMode}
        styles={{
          control: (base) => ({
            ...base,
            height: 40,
            fontSize: 14,
            backgroundColor: selectedCompany
              ? companyExists
                ? editMode
                  ? '#f5f5f5'
                  : 'white'
                : 'rgba(255, 0, 0, 0.2)'  // Red if company doesn't exist
              : 'white',  // Normal background if no company is selected
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
      />

      <Typography variant="h6" sx={{ marginTop: 2, fontSize: 16 }}>Select Policy</Typography>
      <SelectComponent
        options={availablePolicies}
        onChange={(e) => {
          setSelectedPolicy(e.value);
        }}
        value={selectedPolicy ? { label: selectedPolicy, value: selectedPolicy } : null}
        placeholder="Select Policy"
        menuPortalTarget={document.body}
        isDisabled={editMode || !selectedCompany}
        styles={{
          control: (base) => ({
            ...base,
            height: 40,
            fontSize: 14,
            backgroundColor: selectedPolicy
              ? policyExists
                ? editMode
                  ? '#f5f5f5'
                  : 'white'
                : 'rgba(255, 0, 0, 0.2)'  // Red if policy doesn't exist
              : 'white',  // Normal background if no policy is selected
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
      />

      {selectedPolicy && (
        <>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" mt={2}>
            {/* Coverages Section */}
            <Box flex={1} mr={{ xs: 0, md: 1 }} mb={{ xs: 2, md: 0 }}>
              <Typography variant="h6" sx={{ fontSize: 16 }}>Coverages</Typography>
              {coverages.map(([name, value], index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <TextField
                    label="Coverage"
                    value={name}
                    onChange={(e) => handleChange(index, setCoverages, coverages, e.target.value, value)}
                    onBlur={() => handleBlur(index, setCoverages, coverages)}
                    size="small"
                    sx={{
                      flex: 3,
                      marginRight: 1,
                      bgcolor: 'white',
                    }}
                    InputProps={{
                      sx: {
                        fontSize: 12,
                      },
                    }}
                  />
                  <TextField
                    label="Value"
                    value={value}
                    onChange={(e) => handleChange(index, setCoverages, coverages, name, e.target.value)}
                    size="small"
                    sx={{
                      flex: 2,
                      marginRight: 1,
                      bgcolor: 'white',
                    }}
                    InputProps={{
                      sx: {
                        fontSize: 12,
                      },
                    }}
                  />
                  <IconButton onClick={() => handleRemoveCustomOption(setCoverages, index, coverages)} sx={{ marginTop: 0.5 }}>
                    <Remove />
                  </IconButton>
                </Box>
              ))}
              <SelectComponent
                options={[
                  { label: 'Custom', value: 'custom', color: '#f5deb3' }, 
                  ...potentialCoverages.map(([label]) => ({ label, value: label }))
                ]}
                onChange={(selectedItem) => handleSelectChange(selectedItem, setCoverages, coverages, Object.fromEntries(potentialCoverages))}
                placeholder="Add Coverage"
                value={null}
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                components={{
                  MultiValueContainer: () => null,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                styles={{
                  control: (base) => ({ ...base, height: 40, fontSize: 14 }),
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.data.value === 'custom'
                      ? '#f5deb3'
                      : state.isFocused
                      ? '#f5f5f5'
                      : state.isSelected
                      ? 'transparent'
                      : base.backgroundColor,
                    cursor: 'pointer',
                    color: 'inherit',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    display: 'none',
                  }),
                }}
              />
            </Box>

            {/* Excesses Section */}
            <Box flex={1} mr={{ xs: 0, md: 1 }} mb={{ xs: 2, md: 0 }}>
              <Typography variant="h6" sx={{ fontSize: 16 }}>Excesses</Typography>
              {excesses.map(([name, value], index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <TextField
                    label="Excess"
                    value={name}
                    onChange={(e) => handleChange(index, setExcesses, excesses, e.target.value, value)}
                    onBlur={() => handleBlur(index, setExcesses, excesses)}
                    size="small"
                    sx={{
                      flex: 3,
                      marginRight: 1,
                      bgcolor: 'white',
                    }}
                    InputProps={{
                      sx: {
                        fontSize: 12,
                      },
                    }}
                  />
                  <TextField
                    label="Value"
                    value={value}
                    onChange={(e) => handleChange(index, setExcesses, excesses, name, e.target.value)}
                    size="small"
                    sx={{
                      flex: 2,
                      marginRight: 1,
                      bgcolor: 'white',
                    }}
                    InputProps={{
                      sx: {
                        fontSize: 12,
                      },
                    }}
                  />
                  <IconButton onClick={() => handleRemoveCustomOption(setExcesses, index, excesses)} sx={{ marginTop: 0.5 }}>
                    <Remove />
                  </IconButton>
                </Box>
              ))}
              <SelectComponent
                options={[
                  { label: 'Custom', value: 'custom', color: '#f5deb3' }, 
                  ...potentialExcesses.map(([label]) => ({ label, value: label }))
                ]}
                onChange={(selectedItem) => handleSelectChange(selectedItem, setExcesses, excesses, Object.fromEntries(potentialExcesses))}
                placeholder="Add Excess"
                value={null}
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                components={{
                  MultiValueContainer: () => null,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                styles={{
                  control: (base) => ({ ...base, height: 40, fontSize: 14 }),
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.data.value === 'custom'
                      ? '#f5deb3'
                      : state.isFocused
                      ? '#f5f5f5'
                      : state.isSelected
                      ? 'transparent'
                      : base.backgroundColor,
                    cursor: 'pointer',
                    color: 'inherit',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    display: 'none',
                  }),
                }}
              />
            </Box>

            {/* Premiums Section */}
            <Box flex={1}>
              <Typography variant="h6" sx={{ fontSize: 16 }}>Premiums</Typography>
              {premiums.map(([name, value], index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <TextField
                    label="Premium"
                    value={name}
                    onChange={(e) => handleChange(index, setPremiums, premiums, e.target.value, value)}
                    onBlur={() => handleBlur(index, setPremiums, premiums)}
                    size="small"
                    sx={{
                      flex: 3,
                      marginRight: 1,
                      bgcolor: 'white',
                    }}
                    InputProps={{
                      sx: {
                        fontSize: 12,
                      },
                    }}
                  />
                  <TextField
                    label="Value"
                    value={value}
                    onChange={(e) => handleChange(index, setPremiums, premiums, name, e.target.value)}
                    size="small"
                    sx={{
                      flex: 2,
                      marginRight: 1,
                      bgcolor: 'white',
                    }}
                    InputProps={{
                      sx: {
                        fontSize: 12,
                      },
                    }}
                  />
                  <IconButton onClick={() => handleRemoveCustomOption(setPremiums, index, premiums)} sx={{ marginTop: 0.5 }}>
                    <Remove />
                  </IconButton>
                </Box>
              ))}
              <SelectComponent
                options={[
                  { label: 'Custom', value: 'custom', color: '#f5deb3' }, 
                  ...potentialPremiums.map(([label]) => ({ label, value: label }))
                ]}
                onChange={(selectedItem) => handleSelectChange(selectedItem, setPremiums, premiums, Object.fromEntries(potentialPremiums))}
                placeholder="Add Premium"
                value={null}
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                components={{
                  MultiValueContainer: () => null,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                styles={{
                  control: (base) => ({ ...base, height: 40, fontSize: 14 }),
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.data.value === 'custom'
                      ? '#f5deb3'
                      : state.isFocused
                      ? '#f5f5f5'
                      : state.isSelected
                      ? 'transparent'
                      : base.backgroundColor,
                    cursor: 'pointer',
                    color: 'inherit',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    display: 'none',
                  }),
                }}
              />
            </Box>
          </Box>

          {/* Exclusions Section */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ paddingTop: 2 }}>
            <TextField
              label="Filter Exclusions"
              value={exclusionFilter}
              onChange={(e) => setExclusionFilter(e.target.value)}
              size="small"
              sx={{ marginBottom: 2 }}
              InputProps={{
                sx: {
                  fontSize: 12,
                },
              }}
            />
            {unfoundExclusions.length > 0 && (
              <Box mb={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {unfoundExclusions.map((exclusion, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    size="small"
                    onClick={() => handleUnfoundExclusionClick(exclusion)}
                    style={{ margin: '5px' }}
                  >
                    {exclusion}
                  </Button>
                ))}
              </Box>
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={showOnlySelected}
                  onChange={(e) => setShowOnlySelected(e.target.checked)}
                  color="primary"
                />
              }
              label="Show Only Selected"
              sx={{ marginLeft: 2 }}
            />
          </Box>

          <ExclusionsTable
            filteredExclusions={filteredExclusions}
            selectedExclusions={selectedExclusions}
            toggleExclusionSelection={toggleExclusionSelection}
            data={data}
            selectedCompany={selectedCompany}
            selectedPolicy={selectedPolicy}
          />

        </>
      )}
    </>
  );
}

export default PolicySelector;
