import React, { useState, useEffect } from 'react';
import {
    Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Pagination, Grid
} from '@mui/material';
import { Add, Remove, Edit, Delete, Undo, Download, Upload } from '@mui/icons-material';
import * as XLSX from 'xlsx';

function ComparisonTableSection({ comparisonTable, setComparisonTable, updateComparisonTables, companyName, currentPolicyName, comparisonTableLazy }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editRow, setEditRow] = useState([]);
    const [newColumnName, setNewColumnName] = useState('');
    const [isAddColumnDialogOpen, setIsAddColumnDialogOpen] = useState(false);
    const [isConfirmRemoveColumnOpen, setIsConfirmRemoveColumnOpen] = useState(false);
    const [columnToRemove, setColumnToRemove] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [previousTableState, setPreviousTableState] = useState([]); // Store previous state for undo
    const rowsPerPage = 10;

    useEffect(() => {
        if (!Array.isArray(comparisonTable) || comparisonTable.length === 0) {
            setComparisonTable([]);
        }
    }, [comparisonTable]);

    const savePreviousState = () => {
        setPreviousTableState([...comparisonTable]); // Save current table state for undo
    };

    const handleOpenModal = (row = []) => {
        setEditRow(row);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditRow([]);
    };

    const handleSaveRow = () => {
        savePreviousState();
        const updatedTable = [...comparisonTable];
        const existingIndex = updatedTable.findIndex(row => row[0] === editRow[0]);

        if (existingIndex !== -1) {
            updatedTable[existingIndex] = editRow;
        } else {
            updatedTable.push(editRow);
        }

        setComparisonTable(updatedTable);
        updateComparisonTable(updatedTable);
        handleCloseModal();
    };

    const handleRemoveRow = (index) => {
        savePreviousState();
        const actualIndex = (currentPage - 1) * rowsPerPage + (index + 1); // Adjust the index based on pagination
        const updatedTable = comparisonTable.filter((_, i) => i !== actualIndex);
        setComparisonTable(updatedTable);
        updateComparisonTable(updatedTable);
    };

    const handleAddRow = () => {
        const newRow = new Array(headers.length).fill('');
        handleOpenModal(newRow);
    };

    const handleAddColumn = () => {
        if (!newColumnName.trim()) return;

        savePreviousState();
        const updatedHeaders = [...headers, newColumnName];
        const updatedTable = comparisonTable.slice(1).map(row => [...row, '']);
        setComparisonTable([updatedHeaders, ...updatedTable]);
        setNewColumnName('');

        updateComparisonTable([updatedHeaders, ...updatedTable]);
        setIsAddColumnDialogOpen(false);
    };

    const handleRemoveColumn = () => {
        if (columnToRemove === null) return;

        savePreviousState();
        const updatedHeaders = headers.filter((_, index) => index !== columnToRemove);
        const updatedTable = comparisonTable.slice(1).map(row => row.filter((_, index) => index !== columnToRemove));

        setComparisonTable([updatedHeaders, ...updatedTable]);
        updateComparisonTable([updatedHeaders, ...updatedTable]);

        setIsConfirmRemoveColumnOpen(false);
        setColumnToRemove(null);
    };

    const handleUndo = () => {
        if (previousTableState.length > 0) {
            setComparisonTable(previousTableState); // Restore the previous table state
            updateComparisonTable(previousTableState);
            setPreviousTableState([]); // Reset the previous state after undo
        }
    };
    

    const downloadAsXLSX = () => {
        const worksheet = XLSX.utils.aoa_to_sheet(comparisonTable);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ComparisonTable');
        XLSX.writeFile(workbook, `${companyName}_${currentPolicyName}_comparison_table.xlsx`);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
    
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let uploadedTable = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
            // Clean the uploaded table: remove empty rows and fill missing cells
            const maxColumns = Math.max(...uploadedTable.map(row => row.length));
            uploadedTable = uploadedTable
                .filter(row => row.some(cell => cell !== undefined && cell !== ''))  // Remove fully empty rows
                .map(row => [...row, ...new Array(maxColumns - row.length).fill('')]);  // Fill missing cells with empty strings
    
            savePreviousState();
            setComparisonTable(uploadedTable);
            updateComparisonTable(uploadedTable);
        };
    
        reader.readAsArrayBuffer(file);  // Use readAsArrayBuffer instead of readAsBinaryString
    };
    
    const updateComparisonTable = (updatedTable) => {
        updateComparisonTables(comparisonTableLazy, updatedTable);
    };

    const headers = comparisonTable.length > 0 ? comparisonTable[0] : [];
    const tableBody = comparisonTable.length > 1 ? comparisonTable.slice(1) : [];

    // Pagination logic
    const paginatedRows = tableBody.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return (
        <Box mt={5}>
            <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold', mb: 2 }}>
                Comparison Table
            </Typography>
            <Grid container spacing={2}>
                {/* Control Buttons Column */}
                <Grid item xs={12} sm={2}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => setIsAddColumnDialogOpen(true)}
                            fullWidth
                        >
                            Add Column
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={handleAddRow}
                            fullWidth
                            disabled={headers.length === 0} // Disable the button if there are no columns
                        >
                            Add Row
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Undo />}
                            onClick={handleUndo}
                            fullWidth
                            disabled={previousTableState.length === 0} // Disable undo if no previous state exists
                        >
                            Undo
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Download />}
                            onClick={downloadAsXLSX}
                            fullWidth
                        >
                            Download XLSX
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Upload />}
                            component="label"
                            fullWidth
                        >
                            Upload XLSX
                            <input
                                type="file"
                                hidden
                                accept=".xlsx"
                                onChange={handleFileUpload}
                                key={Date.now()} // Add a unique key to reset the input element after each upload
                            />
                        </Button>
                    </Box>
                </Grid>

                {/* Table Content */}
                <Grid item xs={12} sm={10}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {headers.map((header, index) => (
                                        <TableCell key={index} style={{ fontWeight: 'bold', padding: '8px', whiteSpace: 'nowrap' }}>
                                            {header}
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setColumnToRemove(index);
                                                    setIsConfirmRemoveColumnOpen(true);
                                                }}
                                            >
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    ))}
                                    <TableCell align="right" style={{ fontWeight: 'bold', padding: '8px' }}>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedRows.map((row, index) => (
                                    <TableRow key={index} hover>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex} style={{ padding: '8px', maxWidth: '200px', wordBreak: 'break-word' }}>
                                                {cell}
                                            </TableCell>
                                        ))}
                                        <TableCell align="right" style={{ padding: '8px', whiteSpace: 'nowrap' }}>
                                            <IconButton size="small" onClick={() => handleOpenModal(row)}>
                                                <Edit fontSize="small" />
                                            </IconButton>
                                            <IconButton size="small" onClick={() => handleRemoveRow(index)}>
                                                <Remove fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                        <Pagination
                            count={Math.ceil(tableBody.length / rowsPerPage)}
                            page={currentPage}
                            onChange={(event, page) => setCurrentPage(page)}
                            color="primary"
                        />
                    </Box>
                </Grid>
            </Grid>

            {/* Add Column Dialog */}
            <Dialog open={isAddColumnDialogOpen} onClose={() => setIsAddColumnDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Add New Column</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Column Name"
                        value={newColumnName}
                        onChange={(e) => setNewColumnName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAddColumnDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddColumn} color="primary">
                        Add Column
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm Remove Column Dialog */}
            <Dialog open={isConfirmRemoveColumnOpen} onClose={() => setIsConfirmRemoveColumnOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Confirm Remove Column</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to remove the column "{headers[columnToRemove]}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsConfirmRemoveColumnOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveColumn} color="primary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit/Add Row Dialog */}
            <Dialog 
                open={isModalOpen} 
                onClose={handleCloseModal} 
                maxWidth="md" 
                fullWidth 
                disableEnforceFocus // This prevents issues with focus being enforced on hidden elements
            >
                <DialogTitle>{editRow[0] ? 'Edit Row' : 'Add Row'}</DialogTitle>
                <DialogContent>
                    {headers.map((header, index) => (
                        <TextField
                            key={index}
                            label={header}
                            value={editRow[index] || ''}
                            onChange={(e) => {
                                const updatedRow = [...editRow];
                                updatedRow[index] = e.target.value;
                                setEditRow(updatedRow);
                            }}
                            fullWidth
                            margin="normal"
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveRow} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ComparisonTableSection;
