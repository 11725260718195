import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Grid, Button, Container, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Edit } from '@mui/icons-material';
import PolicyDetailsSection from './PolicyView/PolicyDetailsSection';
import PolicyWordingSection from './PolicyView/PolicyWordingSection';
import PolicyExclusionsSection from './PolicyView/PolicyExclusionsSection';
import ComparisonTableSection from './PolicyView/ComparisonTableSection';
import DividerLine from './PolicyView/Formatting/DividerLine';

function PolicyView({libraryData, companyData, comparisonData, policyName, companyName, goBack, updatePolicyDetails, updateComparisonTables }) {
    const [currentPolicyName, setCurrentPolicyName] = useState(policyName);
    const [coverage, setCoverage] = useState({});
    const [excesses, setExcesses] = useState({});
    const [premiums, setPremiums] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPolicyName, setNewPolicyName] = useState('');
    const [policyNameError, setPolicyNameError] = useState('');
    const [wordingLinks, setWordingLinks] = useState({});
    const [exclusions, setExclusions] = useState({});
    const [comparisonTableLazy, setComparisonTableLazy] = useState('');
    const [comparisonTable, setComparisonTable] = useState([]);

    useEffect(() => {
        setCurrentPolicyName(policyName);
        setCoverage(Object.entries(companyData.policies[policyName].coverage || {}));
        setExcesses(Object.entries(companyData.policies[policyName].excesses || {}));
        setPremiums(Object.entries(companyData.policies[policyName].premiums || {}));
        setWordingLinks(Object.entries(companyData.policies[policyName].wording_links || {}));
        setExclusions(Object.entries(companyData.policies[policyName].exclusions || {}));
        setComparisonTableLazy(companyData.policies[policyName].comparison_table_lazy || '');

        // Load the comparison table based on the lazy key
        if (comparisonData && companyData.policies[policyName].comparison_table_lazy) {
            const comparisonKey = companyData.policies[policyName].comparison_table_lazy;
            setComparisonTable(comparisonData[comparisonKey] || []);
        }
    }, [companyData, policyName]);

    const handleEditClick = () => {
        setNewPolicyName(currentPolicyName);
        setPolicyNameError('');
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleSavePolicyName = () => {
        if (!newPolicyName.trim()) {
            setPolicyNameError('Policy name cannot be empty.');
            return;
        }

        // Check if the new policy name already exists under the same company (excluding the current policy name)
        if (companyData.policies.hasOwnProperty(newPolicyName) && newPolicyName !== currentPolicyName) {
            setPolicyNameError('Policy name must be unique.');
            return;
        }

        const updatedPolicy = {
            coverage: Object.fromEntries(coverage),
            excesses: Object.fromEntries(excesses),
            premiums: Object.fromEntries(premiums),
            wording_links: Object.fromEntries(wordingLinks),
            exclusions: Object.fromEntries(exclusions),
            comparison_table_lazy: comparisonTableLazy,
        };

        // Update the policy details with the new policy name
        updatePolicyDetails(companyName, currentPolicyName, updatedPolicy, newPolicyName.trim());

        // After updating, set the new policy name as the current one
        setCurrentPolicyName(newPolicyName.trim());
        setIsModalOpen(false);
    };

    return (
        <Container maxWidth="lg">
            <Box mt={5}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Policy Name"
                            value={currentPolicyName}
                            fullWidth
                            size="small"  // Reduce the size of the input field
                            InputProps={{
                                style: { fontSize: '0.875rem' },  // Smaller text size for a tighter look
                            }}
                            margin="dense"  // Decrease the margin around the input field
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={handleEditClick}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
                <DividerLine/>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <PolicyDetailsSection
                            companyData={companyData}
                            title="Coverages"
                            items={coverage}
                            setItems={setCoverage}
                            updatePolicyDetails={updatePolicyDetails}
                            companyName={companyName}
                            currentPolicyName={currentPolicyName}
                            type="coverage"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PolicyDetailsSection
                            companyData={companyData}
                            title="Excesses"
                            items={excesses}
                            setItems={setExcesses}
                            updatePolicyDetails={updatePolicyDetails}
                            companyName={companyName}
                            currentPolicyName={currentPolicyName}
                            type="excesses"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PolicyDetailsSection
                            companyData={companyData}
                            title="Premiums"
                            items={premiums}
                            setItems={setPremiums}
                            updatePolicyDetails={updatePolicyDetails}
                            companyName={companyName}
                            currentPolicyName={currentPolicyName}
                            type="premiums"
                        />
                    </Grid>
                </Grid>
                <DividerLine/>
                <PolicyWordingSection
                    companyData={companyData}
                    wordingLinks={wordingLinks}
                    setWordingLinks={setWordingLinks}
                    updatePolicyDetails={updatePolicyDetails}
                    companyName={companyName}
                    currentPolicyName={currentPolicyName}
                />
                <DividerLine/>
                <PolicyExclusionsSection
                    companyData={companyData}
                    exclusions={exclusions}
                    setExclusions={setExclusions}
                    updatePolicyDetails={updatePolicyDetails}
                    companyName={companyName}
                    currentPolicyName={currentPolicyName}
                />
                <DividerLine/>
                <ComparisonTableSection
                    comparisonTable={comparisonTable}
                    setComparisonTable={setComparisonTable}
                    updateComparisonTables={updateComparisonTables} 
                    companyName={companyName}
                    currentPolicyName={currentPolicyName}
                    comparisonTableLazy={comparisonTableLazy}
                />

                {/* Modal for editing the policy name */}
                <Dialog open={isModalOpen} onClose={handleModalClose}>
                    <DialogTitle>Edit Policy Name</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter a new name for the policy. It must be unique within the company.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="New Policy Name"
                            type="text"
                            fullWidth
                            value={newPolicyName}
                            onChange={(e) => setNewPolicyName(e.target.value)}
                            error={Boolean(policyNameError)}
                            helperText={policyNameError}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSavePolicyName} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    );
}

export default PolicyView;
