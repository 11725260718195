import React from 'react';
import { Typography, IconButton, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ArrowForward, Undo } from '@mui/icons-material';

const PresentXlsx = ({ xlxsData, handleUndo, hiddenRowsStack, hiddenRows, handleArrowClick }) => (
  
  <>
    {xlxsData?.companies && Object.keys(xlxsData.companies).length > 0 && (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <IconButton
            size="small"
            onClick={handleUndo}
            disabled={hiddenRowsStack.length === 0}
            color="primary"
          >
            <Undo />
          </IconButton>
        </Box>
        {Object.keys(xlxsData.companies).map((company) => {
          const visiblePolicies = Object.keys(xlxsData.companies[company]?.policies || {})
            .filter((policy) => !hiddenRows.some((row) => row.company === company && row.policy === policy));

          return (
            <Box key={company} mb={2}>
              {visiblePolicies.length > 0 && (
                <>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {company} - Policies
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', padding: '6px' }}>Policy Name</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', padding: '6px', textAlign: 'right' }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {visiblePolicies.map((policy) => (
                          <TableRow key={policy} sx={{ backgroundColor: '#FFFFCC' }}>
                            <TableCell sx={{ padding: '6px' }}>{policy}</TableCell>
                            <TableCell sx={{ padding: '6px', textAlign: 'right' }}>
                              <IconButton size="small" onClick={() => handleArrowClick(company, policy)}>
                                <ArrowForward fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Box>
          );
        })}
      </>
    )}
  </>
);

export default PresentXlsx;
