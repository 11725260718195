import React from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import Login from './Pages/UserManagement/Login';
import ProtectedRoute from './CoreComponents/ProtectedRoute';
import PolicyComparisonPage from './Pages/DocumentBuilder/PolicyComparisonPage'
import LibraryOrchestrator from './Pages/DocumentBuilder/LibraryOrchestrator'
import Settings from './Pages/UserManagement/Settings';
import LandingPage from './Pages/UserManagement/BasicLanding';

const theme = createTheme({
    palette: {
      primary: {
        main: '#1c659a', // Replace with your custom primary color
      },
      secondary: {
        main: '#07283d', // Replace with your custom secondary color
      },
      error: {
        main: '#ff1744', // Replace with your custom alternate color
      },
    },
  });

Amplify.configure(awsconfig);

function App() {
    return (
        <ThemeProvider theme={theme}>
        <div>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    
                    {/* Use ProtectedRoute as a wrapper for protected paths */}
                    <Route element={<ProtectedRoute />}>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/🤯" element={<PolicyComparisonPage />} />
                        <Route path="/📚" element={<LibraryOrchestrator />} />
                        <Route path="/🛠" element={<Settings />} />
                    </Route>

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </div>
        </ThemeProvider>
    );
}

export default App;
