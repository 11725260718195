import React, { useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Paper,
  Grid,
} from '@mui/material';

import logo from '../../company-src/InsureFlowLogo.png'; // Import the logo

Amplify.configure(awsconfig);

export default function LoginManager({ onForgotPassword }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [challengeName, setChallengeName] = useState('');
  const [userPass, setUser] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      // Use username or email for login
      const user = await Auth.signIn(username, password);
  
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setChallengeName(user.challengeName);
        setUser(user);
      } else {
        navigate('/files');
      }
    } catch (error) {
      console.error('Error signing in:', error);
      setError(error.message || error);
    }
  };

  const handleNewPasswordRequired = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const loggedUser = await Auth.completeNewPassword(userPass, newPassword);
      navigate('/files');
    } catch (error) {
      console.error('Error changing password:', error);
      setError(error.message || error);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <img src={logo} alt="Logo" style={{ width: '350px', marginBottom: '20px' }} />
          <Typography component="h1" variant="h5">
            InsureFlow
          </Typography>
        </Box>
        <Paper elevation={3} style={{ padding: 16 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {challengeName !== 'NEW_PASSWORD_REQUIRED' ? (
              <form onSubmit={handleLogin} style={{ width: '100%', marginTop: 16 }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"   // Changed label to reflect both username and email
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: 16 }}>
                  Login
                </Button>
                <Button fullWidth variant="text" color="primary" onClick={onForgotPassword}>
                  Forgot Password?
                </Button>
              </form>
            ) : (
              <form onSubmit={handleNewPasswordRequired} style={{ width: '100%', marginTop: 16 }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: 16 }}>
                  Change Password
                </Button>
              </form>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
