import React, { useState } from 'react';
import { TableContainer, Paper, Table, TableBody, TableRow, TableCell, TablePagination, TextField } from '@mui/material';

const ExclusionsTable = ({ filteredExclusions, selectedExclusions, toggleExclusionSelection, data, selectedCompany, selectedPolicy }) => {
  const [page, setPage] = useState(0);  // for pagination
  const [rowsPerPage] = useState(5);  // Fixed pagination at 5 rows per page
  const [searchQuery, setSearchQuery] = useState('');  // for search functionality

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const truncateText = (text, maxLength = 200) => {
    // Replace newlines with space and limit text to the specified number of characters
    const cleanText = text.replace(/\n/g, ' ');
    return cleanText.length > maxLength ? `${cleanText.substring(0, maxLength)}...` : cleanText;
  };

  // Filter exclusions based on the search query
  const searchFilteredExclusions = filteredExclusions.filter(exclusion =>
    exclusion.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the rows to display based on pagination
  const displayedExclusions = searchFilteredExclusions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <TextField
        label="Search Exclusions"
        value={searchQuery}
        onChange={handleSearchChange}
        size="small"
        sx={{ marginBottom: 2 }}
      />
      <TableContainer component={Paper} sx={{ marginBottom: 2, maxHeight: 300, overflow: 'auto' }}>
        <Table size="small" stickyHeader>
          <TableBody>
            {displayedExclusions.map((exclusion) => (
              <TableRow
                key={exclusion}
                onClick={() => toggleExclusionSelection(exclusion)}
                hover
                selected={selectedExclusions.includes(exclusion)}
                style={{
                  backgroundColor: selectedExclusions.includes(exclusion) ? 'lightblue' : 'inherit',
                  cursor: 'pointer',
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }}
              >
                <TableCell
                  sx={{
                    fontSize: 14,
                    backgroundColor: selectedExclusions.includes(exclusion)
                      ? 'rgba(0, 123, 255, 0.2)'  // Highlight selected exclusions
                      : 'inherit'
                  }}
                >
                  {exclusion}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 14,
                    backgroundColor: selectedExclusions.includes(exclusion)
                      ? 'rgba(0, 123, 255, 0.2)'  // Highlight selected exclusions
                      : 'inherit'
                  }}
                >
                  {truncateText(data.companies[selectedCompany].policies[selectedPolicy].exclusions[exclusion])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Pagination Component */}
        <TablePagination
          component="div"
          count={searchFilteredExclusions.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}  // No option to change rows per page
        />
      </TableContainer>
    </>
  );
};

export default ExclusionsTable;
