import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, FormControlLabel, Switch } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const PolicyOverview = ({ comparisonData, handleEditPolicy, handleDeletePolicy }) => {
  const [viewByPolicy, setViewByPolicy] = useState(false);

  const toggleView = () => {
    setViewByPolicy((prev) => !prev);
  };

  const getPoliciesGroupedByPolicy = () => {
    const groupedPolicies = {};

    Object.keys(comparisonData.companies).forEach((company) => {
      Object.keys(comparisonData.companies[company].policies || {}).forEach((policy) => {
        if (!groupedPolicies[policy]) {
          groupedPolicies[policy] = [];
        }
        groupedPolicies[policy].push({
          company,
          policy,
        });
      });
    });

    return groupedPolicies;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Policy Overview</Typography>
        <FormControlLabel
          control={<Switch checked={viewByPolicy} onChange={toggleView} />}
          label={viewByPolicy ? 'By Policy' : 'By Company'}
        />
      </Box>

      {viewByPolicy
        ? Object.entries(getPoliciesGroupedByPolicy()).map(([policy, companies]) => (
            <Box key={policy} mb={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{policy}</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', padding: '6px' }}>Company Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', padding: '6px', textAlign: 'right' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies.map(({ company }) => (
                      <TableRow key={`${company}-${policy}`}>
                        <TableCell sx={{ padding: '6px' }}>{company}</TableCell>
                        <TableCell sx={{ padding: '6px', textAlign: 'right' }}>
                          <IconButton onClick={() => handleEditPolicy(company, policy)} size="small">
                            <Edit fontSize="small" />
                          </IconButton>
                          <IconButton onClick={() => handleDeletePolicy(company, policy)} size="small">
                            <Delete fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))
        : Object.keys(comparisonData.companies).map((company) => (
            <Box key={company} mb={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{company} - Policies</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', padding: '6px' }}>Policy Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', padding: '6px', textAlign: 'right' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(comparisonData.companies[company]?.policies || {}).map((policy) => (
                      <TableRow key={policy}>
                        <TableCell sx={{ padding: '6px' }}>{policy}</TableCell>
                        <TableCell sx={{ padding: '6px', textAlign: 'right' }}>
                          <IconButton onClick={() => handleEditPolicy(company, policy)} size="small">
                            <Edit fontSize="small" />
                          </IconButton>
                          <IconButton onClick={() => handleDeletePolicy(company, policy)} size="small">
                            <Delete fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
    </>
  );
};

export default PolicyOverview;
