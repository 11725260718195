import React, { useState, useEffect } from 'react';
import { 
    Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, 
    TableRow, Paper, IconButton, Grid, Button, Container, Dialog, 
    DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination 
} from '@mui/material';
import { Lock, LockOpen, Delete } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

function CompanyView({ companyData, goBack, updateCompanyDetails, handlePolicyClick, setLibraryData, libraryData }) {
    const [isLocked, setIsLocked] = useState(true);
    const [companyName, setCompanyName] = useState(companyData.name);
    const [legalName, setLegalName] = useState(companyData.legal_name);
    const [insurerRating, setInsurerRating] = useState(companyData.insurer_strength_rating);
    const [newPolicyName, setNewPolicyName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [policyNameError, setPolicyNameError] = useState('');
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [policyToDelete, setPolicyToDelete] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const itemsPerPage = 10;

    useEffect(() => {
        // Sync component state with incoming company data
        setCompanyName(companyData.name);
        setLegalName(companyData.legal_name);
        setInsurerRating(companyData.insurer_strength_rating);
    }, [companyData]);

    // Handle changes in fields (company name, legal name, insurer rating)
    const handleFieldChange = (field, value) => {
        if (field === 'name') {
            setCompanyName(value);
            updateCompanyDetails(companyData.name, {
                ...companyData,
                name: value,
                legal_name: legalName,
                insurer_strength_rating: insurerRating,
            });
        } else if (field === 'legal_name') {
            setLegalName(value);
            updateCompanyDetails(companyData.name, {
                ...companyData,
                name: companyName,
                legal_name: value,
                insurer_strength_rating: insurerRating,
            });
        } else if (field === 'insurer_strength_rating') {
            setInsurerRating(value);
            updateCompanyDetails(companyData.name, {
                ...companyData,
                name: companyName,
                legal_name: legalName,
                insurer_strength_rating: value,
            });
        }
    };

    // Open modal for adding a new policy and blur the button
    const handleAddPolicyClick = (event) => {
        event.currentTarget.blur();  // Prevent focus issues on "Add Policy" button
        setNewPolicyName('');
        setPolicyNameError('');
        setIsModalOpen(true);
    };

    // Close the "Add Policy" modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Save a new policy after validating its name
    const handleSavePolicy = () => {
        if (!newPolicyName) {
            setPolicyNameError('Policy name cannot be empty.');
            return;
        }
        if (companyData.policies.hasOwnProperty(newPolicyName)) {
            setPolicyNameError('Policy name must be unique.');
            return;
        }

        // Generate a unique key for the comparison table and create a new policy
        const comparisonTableKey = `${companyName}_${newPolicyName}_comparison_table_${uuidv4()}`;
        const newPolicy = {
            coverage: {},
            excesses: {},
            premiums: {},
            wording_links: {},
            exclusions: {},
            comparison_table_lazy: comparisonTableKey
        };

        // Update the policies of the company
        const updatedPolicies = {
            ...companyData.policies,
            [newPolicyName]: newPolicy
        };

        // Update the global library data to store the comparison table
        const updatedLibraryData = {
            ...libraryData,
            comparison_tables: {
                ...libraryData.comparison_tables,
                [comparisonTableKey]: [[]]
            }
        };

        // Save updated company details and global data
        updateCompanyDetails(companyName, {
            ...companyData,
            policies: updatedPolicies
        });

        setLibraryData(updatedLibraryData);
        setIsModalOpen(false);
    };

    // Handle Enter key in the "Add Policy" modal to trigger save
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();  // Prevent form submission behavior
            handleSavePolicy();  // Save policy on Enter key press
        }
    };

    // Filter policies based on user input
    const handleFilterChange = (event) => {
        setFilter(event.target.value.toLowerCase());
        setCurrentPage(1);
    };

    // Pagination and filtering logic for policies
    const filteredPolicies = Object.keys(companyData.policies).filter(policy =>
        policy.toLowerCase().includes(filter)
    );
    const paginatedPolicies = filteredPolicies.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Open delete confirmation modal for a policy
    const handleDeleteClick = (policyName) => {
        setPolicyToDelete(policyName);
        setIsDeleteModalOpen(true);
    };

    // Close the delete confirmation modal
    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setPolicyToDelete(null);
    };

    // Confirm policy deletion and update company data
    const handleConfirmDelete = () => {
        const updatedPolicies = { ...companyData.policies };
        delete updatedPolicies[policyToDelete];

        updateCompanyDetails(companyName, {
            ...companyData,
            policies: updatedPolicies
        });

        setIsDeleteModalOpen(false);
        setPolicyToDelete(null);
    };

    return (
        <Container maxWidth="lg">
            <Box mt={5}>
                <Grid container spacing={3}>
                    {/* Left side: Editing company details */}
                    <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold', mb: 2 }}>
                                Edit Company Details
                            </Typography>
                            <IconButton onClick={() => setIsLocked(!isLocked)} style={{ marginLeft: 'auto' }}>
                                {isLocked ? <Lock /> : <LockOpen />}
                            </IconButton>
                        </Box>
                        <TextField
                            label="Company Name"
                            value={companyName}
                            onChange={(e) => handleFieldChange('name', e.target.value)}
                            fullWidth
                            margin="normal"
                            disabled={isLocked}
                        />
                        <TextField
                            label="Legal Name"
                            value={legalName}
                            onChange={(e) => handleFieldChange('legal_name', e.target.value)}
                            fullWidth
                            margin="normal"
                            disabled={isLocked}
                        />
                        <TextField
                            label="Insurer Strength Rating"
                            value={insurerRating}
                            onChange={(e) => handleFieldChange('insurer_strength_rating', e.target.value)}
                            fullWidth
                            margin="normal"
                            disabled={isLocked}
                        />
                    </Grid>

                    {/* Right side: Policy list */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold', mb: 2 }}>
                            Policies
                        </Typography>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <TextField
                                label="Filter Policies"
                                variant="outlined"
                                value={filter}
                                onChange={handleFilterChange}
                                margin="normal"
                            />
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleAddPolicyClick}  // Blur the button after click
                            >
                                Add Policy
                            </Button>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {paginatedPolicies.map((policy, index) => (
                                        <TableRow 
                                            key={index} 
                                            style={{
                                                cursor: 'pointer',
                                                transition: 'background-color 0.3s ease',
                                            }}
                                            onClick={() => handlePolicyClick(companyName, policy)}
                                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ADD8E6'}
                                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
                                        >
                                            <TableCell>{policy}</TableCell>
                                            <TableCell align="right">
                                                <IconButton 
                                                    color="secondary" 
                                                    onClick={(e) => {
                                                        e.stopPropagation();  // Prevent row click
                                                        handleDeleteClick(policy);
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Pagination 
                                count={Math.ceil(filteredPolicies.length / itemsPerPage)} 
                                page={currentPage} 
                                onChange={handlePageChange} 
                                color="primary"
                            />
                        </Box>
                    </Grid>
                </Grid>

                {/* Modal for adding a new policy */}
                <Dialog open={isModalOpen} onClose={handleCloseModal}>
                    <DialogTitle>Add New Policy</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the name of the new policy.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Policy Name"
                            type="text"
                            fullWidth
                            value={newPolicyName}
                            onChange={(e) => setNewPolicyName(e.target.value)}
                            onKeyDown={handleKeyDown}  // Trigger save on Enter key press
                            error={Boolean(policyNameError)}
                            helperText={policyNameError}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleSavePolicy} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Modal for confirming policy deletion */}
                <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete the policy "{policyToDelete}"? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteModal} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmDelete} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    );
}

export default CompanyView;
