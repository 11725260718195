import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Storage, Auth, API } from 'aws-amplify';
import awsmobile from '../../../aws-exports';

function XlsxUploadAndExecuteComponent({ handleData }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to get the current authenticated user's ID
  async function getUserId() {
    const session = await Auth.currentCredentials();
    return session.identityId;
  }

  // Function to get the S3 bucket name dynamically from Amplify Storage configuration
  function getS3BucketName() {
    return awsmobile.Storage.AWSS3.bucket;
  }

  const handleFileUploadAndExecute = async (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    }

    if (
      selectedFile.type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setError('Invalid file type. Please upload an XLSX file.');
      return;
    }

    setError(null);
    setLoading(true);

    try {
      const userId = await getUserId(); // Get the current authenticated user's ID
      const fileName = `${Date.now()}-${selectedFile.name}`;
      const filePath = `private/${userId}/${fileName}`; // Construct the file path in S3
      const bucketName = getS3BucketName();

      // Upload the file to S3 using Amplify Storage
      await Storage.put(filePath, selectedFile, {
        contentType: selectedFile.type,
        level: 'public',
      });

      console.log('File uploaded successfully:', filePath);

      // Get the current session and retrieve the JWT token
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken(); // Get the ID token (JWT)

      // Call the API with the S3 file location as query parameters and the Authorization header
      const response = await API.get(
        awsmobile.aws_cloud_logic_custom[0].name,
        '/excel-reader',
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Authorization header with the token
          },
          queryStringParameters: {
            bucket: bucketName, // Use the dynamic S3 bucket name
            key: `public/${filePath}`, // The S3 key (file path)
          },
        }
      );

      handleData(response);
    } catch (error) {
      setError('Failed to execute the API. Please try again.');
      console.error('Error executing API:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        component="label"
        color="primary"
        disabled={loading}
      >
        {loading ? 'Processing...' : 'Use XLSX File'}
        <input
          type="file"
          accept=".xlsx"
          hidden
          onChange={handleFileUploadAndExecute}
        />
      </Button>

      {error && (
        <Typography color="error" style={{ marginTop: '10px' }}>
          {error}
        </Typography>
      )}
    </div>
  );
}

export default XlsxUploadAndExecuteComponent;
