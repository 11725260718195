import React, { useState, useEffect } from 'react';
import {
  Divider, Typography, Button, Paper, Box, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Grid, Container, Card
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import UK locale
import { Storage } from 'aws-amplify';
import PolicySelector from './policyComparisonPage/PolicySelector';
import GenerateWordDocument from './policyComparisonPage/GenerateWordDocument';
import XlsxUploadAndExecuteComponent from './policyComparisonPage/XlsxUploadComponent';
import updateComparisonData from './policyComparisonPage/updateComparisonData';
import PresentXlsx from './policyComparisonPage/presentXlxsData';
import PolicyOverview from './policyComparisonPage/policyOverview';

function PolicyComparisonPage() {
  const [data, setData] = useState(null);
  const [comparisonData, setComparisonData] = useState({ companies: {}, comparison_tables: {}, fee: 0, expiry: dayjs() });
  const [xlxsData, setXlxsData] = useState({})
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState('');
  const [selectedExclusions, setSelectedExclusions] = useState([]);
  const [coverages, setCoverages] = useState([]);
  const [excesses, setExcesses] = useState([]);
  const [premiums, setPremiums] = useState([]);
  const [fee, setFee] = useState(0); // Default fee value is 0
  const [expiry, setExpiry] = useState(dayjs().add(14, 'day')); // Default expiry date is 2 weeks from today
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [hiddenRows, setHiddenRows] = useState([]); // Tracks currently hidden rows
  const [hiddenRowsStack, setHiddenRowsStack] = useState([]); // Stack for undo functionality  
  const [unfoundExclusions, setUnfoundExclusions] = useState([]); // New state for unfound exclusions

  useEffect(() => {
    const fetchJsonFromS3 = async () => {
      try {
        const file = await Storage.get('shared-with-authenticated/insurance-library/latest/library:latest.json', 
          { download: true, cacheControl: 'no-cache' });
        const jsonData = JSON.parse(await file.Body.text());
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching the JSON file from S3:', error);
      }
    };

    fetchJsonFromS3();
  }, []); // Empty dependency array to run only on page load

  const handleFeeUpdate = (newFee) => {
    setFee(newFee);
    setComparisonData((prevData) => ({
      ...prevData,
      fee: newFee, // Update the fee in the comparison data
    }));
  };

  const handleExpiryChange = (newExpiry) => {
    setExpiry(newExpiry);
    setComparisonData((prevData) => ({
      ...prevData,
      expiry: newExpiry, // Update the expiry date in the comparison data
    }));
  };

  const addPolicy = () => {
    if (!selectedPolicy || !selectedCompany) return;
  
    const companyData = data.companies[selectedCompany];
    const policyData = companyData.policies[selectedPolicy];
  
    setComparisonData((prevData) => ({
      ...prevData,
      companies: {
        ...prevData.companies,
        [selectedCompany]: {
          ...prevData.companies[selectedCompany],
          legal_name: companyData.legal_name,  // Include legal name
          insurer_strength_rating: companyData.insurer_strength_rating,  // Include insurer strength rating
          policies: {
            ...prevData.companies[selectedCompany]?.policies,
            [selectedPolicy]: {
              ...policyData,
              coverage: Object.fromEntries(coverages),
              excesses: Object.fromEntries(excesses),
              premiums: Object.fromEntries(premiums),
              exclusions: selectedExclusions.reduce((acc, exclusion) => {
                acc[exclusion] = policyData.exclusions[exclusion];
                return acc;
              }, {}),
              comparison_table_lazy: policyData.comparison_table_lazy,
            },
          },
        },
      },
      comparison_tables: {
        ...prevData.comparison_tables,
        [policyData.comparison_table_lazy]: data.comparison_tables[policyData.comparison_table_lazy] || {},  // Add only the specific comparison table
      },
    }));
    handleClose();
  };
  
  const handleDialogExited = () => {
    // Cleanup function to reset state after the dialog has fully closed
    setEditMode(false);
    setSelectedExclusions([]);
    setSelectedCompany('');
    setSelectedPolicy('');
    setCoverages([]);
    setExcesses([]);
    setPremiums([]);
    setUnfoundExclusions([]);
  };

  const toggleExclusionSelection = (exclusionName) => {
    setSelectedExclusions((prevSelections) =>
      prevSelections.includes(exclusionName)
        ? prevSelections.filter((exclusion) => exclusion !== exclusionName)
        : [...prevSelections, exclusionName]
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); // Only close the dialog
  };

  const handleEditPolicy = (company, policy) => {
    setSelectedCompany(company);
    setSelectedPolicy(policy);
    setEditMode(true);
    const policyData = comparisonData.companies[company].policies[policy];

    setCoverages(Object.entries(policyData.coverage || {}));
    setExcesses(Object.entries(policyData.excesses || {}));
    setPremiums(Object.entries(policyData.premiums || {}));
    setSelectedExclusions(Object.keys(policyData.exclusions || {}));

    handleOpen();
  };

  const handleArrowClick = (company, policy) => {
    setHiddenRows((prevHiddenRows) => [...prevHiddenRows, { company, policy }]);
    setHiddenRowsStack((prevStack) => [...prevStack, { company, policy }]);
  
    // Existing logic to open the policy dialog
    const companyData = xlxsData.companies[company];
    const policyData = companyData.policies[policy];
  
    setSelectedCompany(company);
    setSelectedPolicy(policy);
    setCoverages(Object.entries(policyData.coverage || {}));
    setExcesses(Object.entries(policyData.excesses || {}));
    setPremiums(Object.entries(policyData.premiums || {}));
    setSelectedExclusions(Object.keys(policyData.exclusions || {}));
    setUnfoundExclusions(policyData.unfoundExclusions || []); // Set unfound exclusions from policy data
    setEditMode(false);
    handleOpen();
  };
  
  const handleUndo = () => {
    if (hiddenRowsStack.length > 0) {
      const lastHiddenRow = hiddenRowsStack[hiddenRowsStack.length - 1]; // Get the last hidden row
      setHiddenRows((prevHiddenRows) =>
        prevHiddenRows.filter(
          (row) => row.company !== lastHiddenRow.company || row.policy !== lastHiddenRow.policy
        )
      );
      setHiddenRowsStack((prevStack) => prevStack.slice(0, -1)); // Remove the last entry from the stack
    }
  };
  

  const handleDeletePolicy = (company, policy) => {
    setComparisonData((prevData) => {
      const updatedCompanies = { ...prevData.companies };
      if (updatedCompanies[company]?.policies) {
        delete updatedCompanies[company].policies[policy];

        // If no policies are left for the company, remove the company
        if (Object.keys(updatedCompanies[company].policies).length === 0) {
          delete updatedCompanies[company];
        }
      }

      return {
        ...prevData,
        companies: updatedCompanies,
      };
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
      <Container maxWidth="lg">
        <Box padding={2}>
          {/* General Settings Box at the top */}
          {/* Left Side - SOA Creation Card */}
          <Box display="flex" justifyContent="center" marginBottom={"10px"}>
            <Card elevation={3} style={{ padding: '16px', maxWidth: 350 }}>
              <Typography variant="h5" align="center" gutterBottom>
                Create an SOA
              </Typography>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Fee"
                    type="number"
                    value={fee || 0}
                    onChange={(e) => handleFeeUpdate(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Expiry"
                    value={expiry}
                    onChange={handleExpiryChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    format="DD/MM/YYYY"
                  />
                </Grid>
              </Grid>

              <Box mt={2} textAlign="center">
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpen}
                    >
                      {editMode ? 'Edit Policy' : 'Add Policy'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <GenerateWordDocument data={comparisonData} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Divider sx={{ my: 2 }}/>


        {/* Dialog for adding/editing policies */}
        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              handleClose();
            }
          }}
          TransitionProps={{ onExited: handleDialogExited }} // Use TransitionProps to trigger cleanup after closing
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>{editMode ? 'Edit Policy' : 'Add a Policy'}</DialogTitle>
          <DialogContent>
            <PolicySelector
              data={data}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              selectedPolicy={selectedPolicy}
              setSelectedPolicy={setSelectedPolicy}
              handlePolicyChange={setSelectedPolicy}
              toggleExclusionSelection={toggleExclusionSelection}
              selectedExclusions={selectedExclusions}
              coverages={coverages}
              setCoverages={setCoverages}
              excesses={excesses}
              setExcesses={setExcesses}
              premiums={premiums}
              setPremiums={setPremiums}
              editMode={editMode}
              unfoundExclusions={unfoundExclusions}
              setUnfoundExclusions={setUnfoundExclusions}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={addPolicy}
              color="primary"
              disabled={
                !selectedPolicy || !selectedCompany || !data?.companies[selectedCompany]?.policies[selectedPolicy]
              }
            >
              {editMode ? 'Save Changes' : 'Add Policy'}
            </Button>
          </DialogActions>
        </Dialog>

          {/* Grid with Left and Right sides */}
          <Grid container spacing={2}>
            {/* Left Side - XLSX Section */}
            <Grid item xs={12} md={6}>
              <PresentXlsx 
                xlxsData={xlxsData} 
                handleUndo={handleUndo} 
                hiddenRowsStack={hiddenRowsStack} 
                hiddenRows={hiddenRows} 
                handleArrowClick={handleArrowClick}
              />
              <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                <XlsxUploadAndExecuteComponent handleData={(processedXlsxData) => {
                  const xlxsOutput = updateComparisonData(processedXlsxData, data);
                  setXlxsData(xlxsOutput)
                  setUnfoundExclusions(xlxsOutput.unfoundExclusions || []); // Capture unfound exclusions
                  handleFeeUpdate(xlxsOutput.fee); 
                }} />
              </Box>
            </Grid>

            {/* Right Side - Final Policies */}
            <Grid item xs={12} md={6}>
              {data && (
                <PolicyOverview
                  comparisonData={comparisonData}
                  handleEditPolicy={handleEditPolicy}
                  handleDeletePolicy={handleDeletePolicy}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </LocalizationProvider>
  );
}

export default PolicyComparisonPage;
